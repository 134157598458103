import React from "react";
import { Button, Form, Modal, Container, Row, Col } from "react-bootstrap";
import "./create-role.scss";
import { userManagement } from "../../../../../redux/store/admin/manager-user/manager-user.action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ShowNotification from "../../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../../utils/configuration";

import Autocomplete from "@material-ui/lab/Autocomplete";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TextField from "@material-ui/core/TextField";
import { roleManagement } from "../../../../../redux/store/admin/role/role-management.action";

const initialState = {
  code: "",
  name: "",
};

class CreateRole extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeCode = this.handleChangeCode.bind(this);
    this.submitRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.roleid !== 0) {
      var role = this.props.rolestate.content.items.find(
        (role) => role.id === this.props.roleid
      );
      if (role) {
        this.setState({
          code: role.code,
          name: role.name,
        });
      }
    }
  }

  handleChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  }

  handleChangeCode(e) {
    const re = /^[a-zA-Z0-9]*$/;
    if (re.test(e.target.value)) {
      var v = e.target.value;
      this.setState({
        ...this.state,
        [e.target.name]: v.toUpperCase(),
      });
    }
  }

  handleClose = () => {
    this.props.onHide();
  };

  validate() {
    if (this.props.roleid === 0) {
      if (this.state.code === "") {
        var code = document.getElementById("code");
        code.focus();
        ShowNotification("Bạn chưa nhập code", NotificationMessageType.Error);
        return false;
      }
      if (this.state.name === "") {
        var name = document.getElementById("name");
        name.focus();
        ShowNotification(
          "Bạn chưa nhập tên role",
          NotificationMessageType.Error
        );
        return false;
      }
    } else {
      if (this.state.name === "") {
        var name = document.getElementById("name");
        name.focus();
        ShowNotification(
          "Bạn chưa nhập tên role",
          NotificationMessageType.Error
        );
        return false;
      }
    }
    return true;
  }

  handleSubmit = () => {
    if (this.validate()) {
      if (this.props.roleid === 0) {
        var params = {
          code: this.state.code,
          name: this.state.name,
        };
        this.props.createrole(params).then((x) => {
          if (!this.props.rolestate.error) {
            this.props.onHide();
          }
        });
      } else {
        var params = {
          id: this.props.roleid,
          code: this.state.code,
          name: this.state.name,
        };
        this.props.updaterole(params).then((x) => {
          if (!this.props.rolestate.error) {
            this.props.onHide();
          }
        });
      }
    }
  };

  render() {
    return (
      <Modal
        {...this.props}
        centered
        dialogClassName="modal-login"
        className="dialog-login"
        scrollable={true}
        size="lg"
        className="create-user"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {this.props.roleid === 0 ? "Thêm quyền" : "Sửa quyền"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {/* onSubmit={this.handleSubmit} */}
            <Form>
              <Form.Row>
                <Form.Group
                  as={Col}
                  // controlId="validationCustom01"
                  className="col-12 col-md-6 col-lg-6"
                >
                  <Form.Label>
                    Code <span className="requite">*</span>
                  </Form.Label>
                  <Form.Control
                    id="code"
                    name="code"
                    onChange={this.handleChangeCode}
                    type="text"
                    value={this.state.code}
                    readOnly={this.props.roleid !== 0 ? true : false}
                  />
                </Form.Group>
                <Form.Group as={Col} className="col-12 col-md-6 col-lg-6">
                  <Form.Label>
                    Tên role <span className="requite">*</span>
                  </Form.Label>
                  <Form.Control
                    id="name"
                    name="name"
                    onChange={this.handleChange}
                    value={this.state.name}
                    type="text"
                    required
                  />
                </Form.Group>
              </Form.Row>
            </Form>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-x mg-r-10"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
            HỦY
          </Button>
          <Button variant="primary" onClick={this.handleSubmit}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-save mg-r-10"
              viewBox="0 0 16 16"
            >
              <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z" />
            </svg>
            LƯU
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  rolestate: state.role,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updaterole: roleManagement.updateRole,
      createrole: roleManagement.createRole,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(CreateRole);
