/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Service from "../../../api/api-service";
import {
  HiddenLoading,
  ShowLoading,
} from "../../../redux/store/loading/loading.store";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";
import { ApiUrl } from "../../../api/api-url";
import history from "../../../common/history";
import DateFormat from "dateformat";
import "./document-new.scss";
import UrlCollect from "../../../common/url-collect";
import { CONSTANT } from "../../../constants/constant";

const service = new Service();
let urlDetail = "";
class DocumentNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: "",
      items: [],
      totalSize: 0,
      page: 1,
      sizePerPage: 15,
      //type doc
      docType: null,
      screenName: "",
    };
    this.fetchData = this.fetchData.bind(this);
    this.changeData = this.changeData.bind(this);
    this.searchData = this.searchData.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    let screenName;
    let docType;
    switch (this.props.location.pathname) {
      case UrlCollect.DocumentWait:
        screenName = CONSTANT.label.titleDocumentWait;
        docType = 1;
        urlDetail = UrlCollect.DocumentWaitDetail;
        break;
      case UrlCollect.DocumentNew:
        screenName = CONSTANT.label.titleDocumentNew;
        docType = 98;
        urlDetail = UrlCollect.DocumentNewDetail;
        break;
      case UrlCollect.DocumentProcess:
        screenName = CONSTANT.label.titleDocumentProcess;
        docType = 99;
        urlDetail = UrlCollect.DocumentProcessDetail;
        break;
      case UrlCollect.DocumentReg:
        screenName = CONSTANT.label.titleDocumentReg;
        docType = 200;
        urlDetail = UrlCollect.DocumentRegDetail;
        break;
      case UrlCollect.DocumentEval:
        screenName = CONSTANT.label.titleDocumentEval;
        docType = 1000;
        urlDetail = UrlCollect.DocumentEvalDetail;
        break;
      default:
        break;
    }
    this.setState(
      {
        ...this.state,
        docType: docType,
        screenName: screenName,
      },
      () => {
        this.fetchData();
      }
    );
  }
  handleChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  }
  searchData(e) {
    this.fetchData();
  }
  fetchData() {
    let page = this.state.page;
    let sizePerPage = this.state.sizePerPage;
    this.props.ShowLoading();
    service
      .post(ApiUrl.GetListAllByStatus, {
        keyword: this.state.keyword,
        status: this.state.docType,
        pageIndex: page,
        pageSize: sizePerPage,
      })
      .then((response) => {
        this.setState({
          items: response.content.items,
          totalSize: response.content.totalItemCount,
          page,
          sizePerPage,
        });
        this.props.HiddenLoading();
      })
      .catch((error) => {
        this.props.HiddenLoading();
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  }
  changeData(type, newState) {
    let page = newState.page;
    let sizePerPage = newState.sizePerPage;
    this.props.ShowLoading();
    service
      .post(ApiUrl.GetListAllByStatus, {
        keyword: this.state.keyword,
        status: this.state.docType,
        pageIndex: page,
        pageSize: sizePerPage,
      })
      .then((response) => {
        this.setState({
          items: response.content.items,
          totalSize: response.content.totalItemCount,
          page,
          sizePerPage,
        });
        this.props.HiddenLoading();
      })
      .catch((error) => {
        this.props.HiddenLoading();
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  }
  render() {
    const columns = [
      {
        dataField: "name",
        text: "Hồ sơ",
      },
      {
        dataField: "projectName",
        text: "Tên dự án",
      },
      {
        dataField: "listDistrictName",
        text: "Quận/ huyện",
        headerStyle: (colum, colIndex) => {
          return { width: "8%" };
        },
      },
      {
        dataField: "listProvinceName",
        text: "Tỉnh/ thành",
        headerStyle: (colum, colIndex) => {
          return { width: "8%" };
        },
      },
      {
        dataField: "evaluateRecordsTypeName",
        text: "Danh mục hồ sơ",
      },
      {
        dataField: "sendDate",
        text: "Ngày nhận",
        formatter: (cell) => {
          return (
            <div style={{ overflowWrap: "break-word" }}>
              {DateFormat(cell, "dd/mm/yyyy HH:MM")}
            </div>
          );
        },
        headerStyle: () => {
          //return { width: "24%" };
        },
      },
      {
        dataField: "timeLeft",
        text: "Thời gian",
        formatter: (cellContent, row) => {
          return (
            <span class="text-danger font-italic font-s10-c">
              {row.timeLeft}
            </span>
          );
        },
      },
      {
        dataField: "statusName",
        text: "Trạng thái",
        headerStyle: (colum, colIndex) => {
          return { width: "8%" };
        },
      },
    ];
    const RemotePagination = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div>
        <BootstrapTable
          remote
          keyField="id"
          data={data}
          columns={columns}
          pagination={paginationFactory({
            page,
            sizePerPage,
            totalSize,
            alwaysShowAllBtns: true,
            hideSizePerPage: true,
          })}
          onTableChange={onTableChange}
          rowEvents={rowEvents}
          classes="table-striped"
          rowClasses="mytable-hover-row"
        />
      </div>
    );
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        history.push({
          pathname: urlDetail + "/" + row.id,
          //search: '?query=abc',
          /*
          state: {
            iddoc: row.id,
            screenName: this.state.screenName,
            titleName: row.projectName,
            urlParent: this.props.location.pathname,
          },
          */
        });
      },
    };
    return (
      <div class="layout-container pd15 document-new">
        <div class="row no-gutters border-bottom">
          <div class="col-4">
            <div class="title-doc-new">{this.state.screenName}</div>
          </div>
          <div class="col-8 d-flex justify-content-end">
            <div class="form-group offset-md-1">
              <div class="row no-gutters d-flex justify-content-end">
                <div class="col-7 mg-1">
                  <input
                    type="text"
                    class="form-control"
                    id="filerAddress"
                    value={this.state.keyword}
                    onChange={this.handleChange}
                    name="keyword"
                    placeholder=""
                  />
                </div>
                <div class="col-5 col-sm-4">
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={this.searchData}
                  >
                    Tìm kiếm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row no-gutters mg-top">
          <RemotePagination
            data={this.state.items}
            page={this.state.page}
            sizePerPage={this.state.sizePerPage}
            totalSize={this.state.totalSize}
            onTableChange={(type, newState) => {
              this.changeData(type, newState);
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  weblink: state.weblink,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ShowLoading: ShowLoading,
      HiddenLoading: HiddenLoading,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(DocumentNew);
