/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  HiddenLoading,
  ShowLoading,
} from "../../../redux/store/loading/loading.store";
import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
import { userAction } from "../../../redux/store/admin/user/user.action";
import { helpingAddRecordAction } from "../../../redux/store/admin/helping-add-record/helping-add-record.action";
import DateFormat from "dateformat";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";
import history from "../../../common/history";
import UrlCollect from "../../../common/url-collect";
import apiEnvironment from "../../../api/api-config";
import AssignUserModal from "../modal/assign-modal/assign-user-modal";
import ManagerCommentModal from "../modal/manager-comment-modal/manager-comment-modal";
import LeaderCommentModal from "../modal/leader-comment-modal/leader-comment-modal";
import HelpingAddRecord from "../modal/helping-add-record/helping-add-record";
import RecordModal from "../modal/record-modal/record-modal";
import ConfirmModalView from "../modal/confirm-modal/confirm-modal";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { CONSTANT } from "../../../constants/constant";
import * as configuration from "../../../utils/configuration";
import "react-datepicker/dist/react-datepicker.css";
import "react-dual-listbox/lib/react-dual-listbox.css";
import "./document-new-detail.scss";
var FileSaver = require("file-saver");

const service = new Service();
let urlBack = "";
let screenName = "";
let isHELPINGADDRECORDS = false;
let isRejectHELPINGADDRECORDS = false;
let dataGruopLookup = [];
let allowEditGruop = false;

class DocumentNewDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //iddoc: props.location.state ? props.location.state.iddoc : null,
      iddoc: this.props.match.params ? this.props.match.params.id : null,
      //screenName: props.location.state ? props.location.state.screenName : "",
      //titleName: props.location.state ? props.location.state.titleName : "",
      //urlParent: props.location.state ? props.location.state.urlParent : "",
      startDate: new Date(),
      btnClick: null,
      //modal
      assignUserProcessShow: false,
      managerShow: false,
      leaderShow: false,
      recordShow: false,
      typeRecord: null,
      helpingAddRecordShow: null,
      isOpenConfirmModal: false,
      titleRecord: "",
      //data
      gruopAutoCompleteSelected: null,
      timeLeft: "",
      company: {
        name: null,
        taxCode: null,
        address: null,
        phoneNumber: null,
        faxNumber: null,
        email: null,
        provinceId: null,
        provinceName: null,
        districtId: null,
        districtName: null,
        communeId: null,
        communeName: null,
        created_by: null,
        created_date: null,
        modified_by: null,
        modified_date: null,
        id: null,
      },
      sentUser: {
        id: null,
        email: null,
        fullName: null,
        identityId: null,
        identityDate: null,
        identityBy: null,
        dateOfBirth: null,
        sex: null,
        address: null,
        createdDate: null,
        modifiedDate: null,
        createdBy: null,
        modifiedBy: null,
        avatar: null,
        status: null,
        description: null,
        roleName: null,
        phoneNumber: null,
        companyName: null,
      },
      resultFile: null,
      listDocFile: null,
      projectName: null,
      name: null,
      evaluateRecordsTypeName: null,
      address: null,
      lDistrict: null,
      lProvince: null,
      acreage: null,
      totalInvestment: null,
      evaluateRecordsGroupName: null,
      feesRate: null,
      fees: null,
      workflows: null,
      leaderOpinions: null,
      currentStep: null,
      status: null,
      id: null,
      documentTypeId: null,
        code: null,
        sendDate: new Date(),
        dateExpired: new Date(),
        recordsNo:null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.setStartDate = this.setStartDate.bind(this);
    this.btnSbtClick = this.btnSbtClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleShowModal = this.handleShowModal.bind(this);
    this.assignUserProcessSave = this.assignUserProcessSave.bind(this);
    this.approve = this.approve.bind(this);
    this.reject = this.reject.bind(this);
    this.addLeaderOpinion = this.addLeaderOpinion.bind(this);
    this.addUserOpinion = this.addUserOpinion.bind(this);
    this.getEvaluateRecordsByID = this.getEvaluateRecordsByID.bind(this);
    this.updateResultFile = this.updateResultFile.bind(this);
    this.updateHelpingAddRecords = this.updateHelpingAddRecords.bind(this);
    this.handleAcceptModalConfirm = this.handleAcceptModalConfirm.bind(this);
    this.sendNotifyComplete = this.sendNotifyComplete.bind(this);
    this.getLookupEvaluateRecordsGroup = this.getLookupEvaluateRecordsGroup.bind(
      this
    );
    this.handleChangeAutocomplete = this.handleChangeAutocomplete.bind(this);
    this.saveGroup = this.saveGroup.bind(this);
  }
  componentDidMount() {
    allowEditGruop = false;
    let urlCheck = "";
    if (this.props.location.pathname) {
      let idx = this.props.location.pathname.lastIndexOf("/");
      urlCheck = this.props.location.pathname.substring(0, idx);
    }
    switch (urlCheck) {
      case UrlCollect.DocumentWaitDetail:
        screenName = CONSTANT.label.titleDocumentWait;
        urlBack = UrlCollect.DocumentWait;
        break;
      case UrlCollect.DocumentNewDetail:
        screenName = CONSTANT.label.titleDocumentNew;
        urlBack = UrlCollect.DocumentNew;
        break;
      case UrlCollect.DocumentProcessDetail:
        screenName = CONSTANT.label.titleDocumentProcess;
        urlBack = UrlCollect.DocumentProcess;
        break;
      case UrlCollect.DocumentRegDetail:
        screenName = CONSTANT.label.titleDocumentReg;
        urlBack = UrlCollect.DocumentReg;
        break;
      case UrlCollect.DocumentEvalDetail:
        screenName = CONSTANT.label.titleDocumentEval;
        urlBack = UrlCollect.DocumentEval;
        break;
      default:
        break;
    }
    this.getEvaluateRecordsByID();
  }
  updateHelpingAddRecords(data) {
    let dataInput = {
      id: this.props.helpingAddRecord.content.id, // not edit
      name: this.props.helpingAddRecord.content.name, // not edit
      content: data.data,
      documentId: this.state.id, // not edit
      documentTypeId: this.state.documentTypeId, // not edit
      code: this.props.helpingAddRecord.content.code, // not edit
    };
    this.props.ShowLoading();
    service
      .post(ApiUrl.UpdateHelpingAddRecords, dataInput)
      .then((response) => {
        this.props.HiddenLoading();
        //this.getEvaluateRecordsByID();
        this.setState({ helpingAddRecordShow: false });

        //reword no.21
        if (isRejectHELPINGADDRECORDS) {
          const data = {
            currentWorkflowId: this.state.currentStep.id,
            documentId: this.state.id,
            documentTypeId: this.state.documentTypeId,
          };
          this.reject(data);
        }
      })
      .catch((error) => {
        this.props.HiddenLoading();
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  }
  updateResultFile(data) {
    this.props.ShowLoading();
    service
      .post(ApiUrl.UpdateResultFile, data)
      .then((response) => {
        this.props.HiddenLoading();
        this.getEvaluateRecordsByID();
        this.setState({ recordShow: false });
      })
      .catch((error) => {
        this.props.HiddenLoading();
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  }
  handleChangeAutocomplete(event, value) {
    const itemSelect = dataGruopLookup.find((item) => item.id === value.id);
    this.setState({
      gruopAutoCompleteSelected: itemSelect,
    });
  }
  saveGroup() {
    this.props.ShowLoading();
    service
      .post(ApiUrl.UpdateGroup, {
        id: this.state.iddoc,
        evaluateRecordsGroupId: this.state.gruopAutoCompleteSelected.id,
      })
      .then((response) => {
        this.props.HiddenLoading();
        this.getEvaluateRecordsByID();
      })
      .catch((error) => {
        this.props.HiddenLoading();
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  }
  getLookupEvaluateRecordsGroup(evaluateRecordsTypeId, evaluateRecordsGroupId) {
    this.props.ShowLoading();
    service
      .get(
        ApiUrl.GetLookupEvaluateRecordsGroup +
          "?evaluateRecordsTypeId=" +
          evaluateRecordsTypeId
      )
      .then((response) => {
        this.props.HiddenLoading();
        if (response && response.content) {
          dataGruopLookup = response.content;
          const itemSelect = dataGruopLookup.find(
            (item) => item.id === evaluateRecordsGroupId
          );
          this.setState({
            gruopAutoCompleteSelected: itemSelect,
          });
        }
      })
      .catch((error) => {
        this.props.HiddenLoading();
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  }
  getEvaluateRecordsByID() {
    this.props.ShowLoading();
    service
      .get(ApiUrl.GetEvaluateRecordsByID + this.state.iddoc)
      .then((response) => {
        if (response && response.content) {
          this.setState({
            ...this.state,
            company: response.content.company,
            sentUser: response.content.sentUser
              ? response.content.sentUser
              : this.state.sentUser,
            resultFile: response.content.resultFile,
            listDocFile: response.content.listFile,
            projectName: response.content.projectName,
            name: response.content.name,
            evaluateRecordsTypeName: response.content.evaluateRecordsTypeName,
            address: response.content.address,
            lDistrict: response.content.listDistrictName,
            lProvince: response.content.listProvinceName,
            timeLeft: response.content.timeLeft,
            acreage: response.content.acreage,
            totalInvestment: response.content.totalInvestment,
            evaluateRecordsGroupName: response.content.evaluateRecordsGroupName,
            feesRate: response.content.feesRate,
            fees: response.content.fees,
            workflows: response.content.workflows,
            leaderOpinions: response.content.leaderOpinions,
            currentStep: response.content.currentStep,
            status: response.content.status,
            id: response.content.id,
            documentTypeId: response.content.documentTypeId,
              code: response.content.code,
              sendDate: response.content.sendDate,
              dateExpired: response.content.dateExpired,
              recordsNo: response.content.recordsNo,
          });
        }
        this.props.HiddenLoading();
        this.getLookupEvaluateRecordsGroup(
          response.content.evaluateRecordsTypeId,
          response.content.evaluateRecordsGroupId
        );
      })
      .catch((error) => {
        this.props.HiddenLoading();
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  }
  addUserOpinion(data) {
    this.props.ShowLoading();
    service
      .post(ApiUrl.AddUserOpinion, data)
      .then((response) => {
        this.props.HiddenLoading();
        this.getEvaluateRecordsByID();
        this.setState({ leaderShow: false });
      })
      .catch((error) => {
        this.props.HiddenLoading();
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  }
  addLeaderOpinion(data) {
    this.props.ShowLoading();
    service
      .post(ApiUrl.AddLeaderOpinion, data)
      .then((response) => {
        this.props.HiddenLoading();
        this.getEvaluateRecordsByID();
        this.setState({ managerShow: false });
      })
      .catch((error) => {
        this.props.HiddenLoading();
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  }
  sendNotifyComplete(data) {
    this.props.ShowLoading();
    service
      .post(ApiUrl.SendNotifyComplete, data)
      .then((response) => {
        this.props.HiddenLoading();
        ShowNotification(
          CONSTANT.message.sendNotifyCompleteMsg,
          NotificationMessageType.Success
        );
      })
      .catch((error) => {
        this.props.HiddenLoading();
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  }
  reject(data) {
    this.props.ShowLoading();
    service
      .post(ApiUrl.Reject, data)
      .then((response) => {
          this.props.HiddenLoading();
          this.getEvaluateRecordsByID();
        //history.push({
        //  pathname: urlBack,
        //});
      })
      .catch((error) => {
        this.props.HiddenLoading();
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  }
  approve(data) {
    this.props.ShowLoading();
    service
      .post(ApiUrl.Approve, data)
      .then((response) => {
          this.props.HiddenLoading();
          this.getEvaluateRecordsByID();
        //history.push({
        //  pathname: urlBack,
        //});
      })
      .catch((error) => {
        this.props.HiddenLoading();
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  }
  assignUserProcessSave(arrayUserId, content) {
    const data = {
      documentId: this.state.id,
      currentStep: this.state.currentStep.id,
      documentTypeId: this.state.documentTypeId,
        userIds: arrayUserId,
      description: content
      };
      debugger
    this.props.ShowLoading();
    service
      .post(ApiUrl.AssignUserProcessSave, data)
      .then((response) => {
        this.props.HiddenLoading();
        this.setState({ assignUserProcessShow: false });
      })
      .catch((error) => {
        this.props.HiddenLoading();
        this.setState({ assignUserProcessShow: false });
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  }
  handleShowModal(actionType) {
    if (actionType === "ASSIGNUSERPROCESS") {
      this.props.getUserByDeparment(() => {
        this.setState({ assignUserProcessShow: true });
      });
    } else if (actionType === "HELPINGADDRECORDS") {
      this.props.getHelpingAddRecord(
        this.state.id,
        this.state.documentTypeId,
        () => {
          this.setState({ helpingAddRecordShow: true });
        }
      );
    } else if (actionType === "UPDATERESULT") {
      this.setState({
        recordShow: true,
        typeRecord: "UPDATERESULT",
        titleRecord: "Cập Nhập Kết Quả",
      });
    } else if (actionType === "CANCELRECORDS") {
      this.setState({
        recordShow: true,
        typeRecord: "CANCELRECORDS",
        titleRecord: "Hủy Kết Quả",
      });
    } else if (actionType === "updateComemntManagerModal") {
      this.setState({ managerShow: true });
    } else if (actionType === "updateComemntLeaderModal") {
      this.setState({ leaderShow: true });
    }
  }
  handleCloseModal(type, data) {
    switch (type) {
      case "close-assign":
        this.setState({ assignUserProcessShow: false });
        break;
      case "save-assign":
        this.assignUserProcessSave(data.data, data.content);
        break;
      case "close-manager":
        this.setState({ managerShow: false });
        break;
      case "save-manager":
        const user = configuration.getUserInfo2();
        const dataInput = {
          documentId: this.state.id,
          documentTypeId: this.state.documentTypeId,
          content: data.data,
          leaderUserId: user.userId,
        };
        this.addLeaderOpinion(dataInput);
        break;
      case "close-helping":
        isRejectHELPINGADDRECORDS = false;
        this.setState({ helpingAddRecordShow: false });
        break;
      case "save-helping":
        this.updateHelpingAddRecords(data);
        break;
      case "close-leader":
        this.setState({ leaderShow: false });
        break;
      case "save-leader":
        const formData = new FormData();
        formData.append("WorkFlowId", this.state.currentStep.id);
        formData.append("Description", data.description);
        formData.append("IsNoCertification", data.isNoCertification);
        if (data.fileList.length > 0) {
          for (let i = 0; i < data.fileList.length; i++) {
            formData.append("files", data.fileList[i]);
          }
        }
        this.addUserOpinion(formData);
        break;
      case "close-record":
        this.setState({ recordShow: false });
        break;
      case "save-record":
        const dataIp = new FormData();
        if (this.state.typeRecord === "UPDATERESULT") {
          if (
            this.state.currentStep &&
            this.state.currentStep.isNoCertification
          ) {
            dataIp.append("WorkFlowId", this.state.currentStep.id);
            dataIp.append("Description", data.description);
            dataIp.append("ElementType", 2);
            dataIp.append(
              "ElementName",
              "Quyết định không cấp giấy chứng nhận"
            );
            dataIp.append("DocumentId", this.state.id);
            dataIp.append("file", data.file);
          } else {
            dataIp.append("WorkFlowId", this.state.currentStep.id);
            dataIp.append("Description", data.description);
            dataIp.append("ElementType", 1);
            dataIp.append("ElementName", "Giấy chứng nhận");
            dataIp.append("DocumentId", this.state.id);
            dataIp.append("RecordsNo", data.recordsNo);
            dataIp.append("file", data.file);
          }
        } else if (this.state.typeRecord === "CANCELRECORDS") {
          dataIp.append("WorkFlowId", this.state.currentStep.id);
          dataIp.append("ElementType", 4);
          dataIp.append("ElementName", "Quyết định hủy kết quả");
          dataIp.append("DocumentId", this.state.id);
          dataIp.append("file", data.file);
        }
        this.updateResultFile(dataIp);
        break;
      default:
        break;
    }
  }
  btnSbtClick(btnName) {
    this.setState({
      ...this.state,
      btnClick: btnName,
    });
  }
  handleAcceptModalConfirm() {
    if (this.state.btnClick === "REJECT") {
      const data = {
        currentWorkflowId: this.state.currentStep.id,
        documentId: this.state.id,
        documentTypeId: this.state.documentTypeId,
      };
      this.reject(data);
    } else if (this.state.btnClick === "APPROVAL") {
      const data = {
        currentWorkflowId: this.state.currentStep.id,
        documentId: this.state.id,
        documentTypeId: this.state.documentTypeId,
      };
      this.approve(data);
    }
  }
  handleSubmit(event) {
    event.preventDefault();
    if (this.state.btnClick === "BACK") {
      history.push({
        pathname: urlBack,
      });
    } else if (this.state.btnClick === "NOTIFYCOMPLETE") {
      const data = {
        recordsId: this.state.id,
      };
      this.sendNotifyComplete(data);
    } else if (this.state.btnClick === "REJECT") {
      if (isHELPINGADDRECORDS) {
        this.props.getHelpingAddRecord(
          this.state.id,
          this.state.documentTypeId,
          () => {
            isRejectHELPINGADDRECORDS = true;
            this.setState({ helpingAddRecordShow: true });
          }
        );
      } else {
        this.setState({
          ...this.state,
          isOpenConfirmModal: true,
        });
      }
    } else if (this.state.btnClick === "APPROVAL") {
      this.setState({
        ...this.state,
        isOpenConfirmModal: true,
      });
    }
  }
  setStartDate(date) {
    this.setState({
      ...this.state,
      startDate: date,
    });
  }
  handleChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  }

  render() {
    const iconRight = (
      <svg
        width="14"
        height="14"
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="caret-right"
        class="svg-inline--fa fa-caret-right fa-w-6"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 192 512"
      >
        <path
          fill="black"
          d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z"
        ></path>
      </svg>
    );

    const iconDownload = (
      <svg
        width="14"
        height="14"
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="download"
        class="svg-inline--fa fa-download fa-w-16"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="#007bff"
          d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
        ></path>
      </svg>
    );

    const iconView = (
      <svg
        width="14"
        height="14"
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="eye"
        class="svg-inline--fa fa-eye fa-w-18"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 576 512"
      >
        <path
          fill="#007bff"
          d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"
        ></path>
      </svg>
    );

    const leaderCommentItems = [];
    const managerCommentItems = [];
    const btnList = [];
    let isShowBtnCommentLeader = false;
    let isShowBtnCommentManager = false;
      let rowResultFile = [];
      let rowReportFile = [];
    let rowlistDocFile = [];

    //render tableDocFile
    if (this.state.listDocFile && this.state.listDocFile.length > 0) {
      this.state.listDocFile.map((item, index) => {
        let nameTb = item.recordElement ? item.recordElement.name : "";
        let fileName = item.file ? item.file.fileName : "";
        let filePreview = item.file ? item.file.filePreview : "";
        rowlistDocFile.push(
          <tr class="d-flex">
            <th class="col-1">{index+1}</th>
            <td class="col-5">{nameTb}</td>
                <td class="clTitleSup col-5">
                    <span
                        class="cursor-pointer"
                        onClick={() => {
                            window.open(apiEnvironment.api + filePreview, "_blank");
                        }}
                    >{fileName}</span></td>
                {
                    //<td class="text-center col-1">
                    //    <span
                    //        class="cursor-pointer"
                    //        onClick={() => {
                    //            window.open(apiEnvironment.api + filePreview, "_blank");
                    //        }}
                    //    >
                    //        {iconView}
                    //    </span>
                    //</td>
                }
            <td class="text-center col-1">
              <span
                class="cursor-pointer"
                onClick={() => {
                  FileSaver.saveAs(apiEnvironment.api + filePreview, fileName);
                }}
              >
                {iconDownload}
              </span>
            </td>
          </tr>
        );
      });
    }

    //render tableResultFile
    if (this.state.resultFile && this.state.resultFile.length > 0) {
      this.state.resultFile.map((item, index) => {
        let fileName = item.file ? item.file.fileName : "";
        let filePreview = item.file ? item.file.filePreview : "";
        rowResultFile.push(
          <tr class="d-flex">
            <th class="col-1">{index+1}</th>
            <td class="clTitleSup col-10">{fileName}</td>
            <td class="text-center col-1">
              <span
                class="cursor-pointer"
                onClick={() => {
                  FileSaver.saveAs(apiEnvironment.api + filePreview, fileName);
                }}
              >
                {iconDownload}
              </span>
            </td>
          </tr>
        );
      });
    }

    //check show/hide btn CommentManager
    if (this.state.status < 1000) {
      isShowBtnCommentManager = true;
    }

    //list btn
    btnList.push(
      <button
        class="btn btn-secondary mr-2"
        type="submit"
        name="BACK"
        onClick={() => {
          this.btnSbtClick("BACK");
        }}
      >
        Quay Lại
      </button>
    );
    if (
      this.state.currentStep &&
      this.state.currentStep.stepActions &&
      this.state.currentStep.stepActions.length > 0
    ) {
      let isShowBtnApproval = false;
      let isShowBtnReject = false;
      this.state.currentStep.stepActions.map((item, index) => {
        if (item.action === "EDITGROUP" && dataGruopLookup.length > 0) {
          allowEditGruop = true;
        }
        if (item.action === "HELPINGADDRECORDS") {
          isHELPINGADDRECORDS = true;
        }
        if (item.buttonLabel) {
          if (item.action === "REJECT") {
            isShowBtnReject = true;
          }
          if (item.action === "APPROVAL") {
            isShowBtnApproval = true;
          }
          if (
            item.action === "ASSIGNUSERPROCESS" ||
            item.action === "HELPINGADDRECORDS" ||
            item.action === "UPDATERESULT" ||
            item.action === "CANCELRECORDS"
          ) {
            btnList.push(
              <button
                class="btn btn-primary mr-2"
                type="button"
                name={item.action}
                onClick={() => {
                  this.handleShowModal(item.action);
                }}
              >
                {item.buttonLabel}
              </button>
            );
          } else if (item.action === "REJECT") {
            btnList.push(
              <button
                class="btn btn-warning mr-2"
                type="submit"
                name={item.action}
                onClick={() => {
                  this.btnSbtClick(item.action);
                }}
              >
                {item.buttonLabel}
              </button>
            );
          } else if (item.action === "NOTIFYCOMPLETE") {
            btnList.push(
              <button
                class="btn btn-primary mr-2"
                type="submit"
                name={item.action}
                onClick={() => {
                  this.btnSbtClick(item.action);
                }}
              >
                {item.buttonLabel}
              </button>
            );
          } else {
            btnList.push(
              <button
                class="btn btn-primary mr-2"
                type="submit"
                name={item.action}
                onClick={() => {
                  this.btnSbtClick(item.action);
                }}
              >
                {item.buttonLabel}
              </button>
            );
          }
        }
      });
      if (isShowBtnApproval && isShowBtnReject) {
        isShowBtnCommentLeader = true;
      }
    }
    //end list btn

    //list comment leader
      if (this.state.workflows && this.state.workflows.length > 0) {
          let indexRowReport = 0;
      this.state.workflows.map((item, index) => {
        const listFiles = [];
        if (item.listFile && item.listFile.length > 0) {
            item.listFile.map((itemF, indexF) => {
                indexRowReport = indexRowReport + 1;
            listFiles.push(
              <div
                class="pl-3 w-100"
                onClick={() => {
                  const urlF = itemF.filePreview;
                  window.open(apiEnvironment.api + urlF, "_blank");
                }}
              >
                <a href="#">
                  {" "}
                  {iconRight} <span class="clTitleSup">{itemF.fileName}</span>
                </a>
              </div>
              );
              rowReportFile.push(
                  <tr class="d-flex">
                      <th class="col-1">{indexRowReport}</th>
                      <td class="col-3">{item.userApprovalFullName}</td>
                      <td class="clTitleSup col-7">
                          <span
                              class="cursor-pointer"
                              onClick={() => {
                                  window.open(apiEnvironment.api + itemF.filePreview, "_blank");
                              }}
                          >{itemF.fileName}</span></td>
                      <td class="text-center col-1">
                          <span
                              class="cursor-pointer"
                              onClick={() => {
                                  FileSaver.saveAs(apiEnvironment.api + itemF.filePreview, itemF.fileName);
                              }}
                          >
                              {iconDownload}
                          </span>
                      </td>
                  </tr>
              );
          });
        }
        let userApprovalFullName = item.userApprovalFullName + " - ";
        if (item.step && item.step !== 0) {
          userApprovalFullName = "";
        }
        let approvalDateF = () => {
          return (
            <div>
              {item.statusName}{" "}
              {DateFormat(item.approvalDate, "dd/mm/yyyy HH:MM")}
            </div>
          );
        };
        if (item.approvalDate === "1900-01-01T00:00:00") {
          approvalDateF = () => {
            return (
              <div>
                {item.statusName}{" "}
                <span class="text-danger font-italic font-s10-c">
                  {item.timeLeft}
                </span>
              </div>
            );
          };
        }

        leaderCommentItems.push(
          <div class="row no-gutters vertical-center mb-4" key={index}>
            <div class="name-approve">{userApprovalFullName} </div>{" "}
            <div class="date-approve">
              <div>{approvalDateF()}</div>
            </div>
            {item.description && (
              <div class="pl-2 w-100">
                {" "}
                {iconRight} {item.description}
              </div>
            )}
            {listFiles.length > 0 && <div class="pl-2 w-100"> {listFiles}</div>}
          </div>
        );
      });
    }
    //end list comment leader

    //list comment manager
    if (this.state.leaderOpinions && this.state.leaderOpinions.length > 0) {
      this.state.leaderOpinions.map((item, index) => {
        managerCommentItems.push(
          <div class="row no-gutters vertical-center mb-4" key={index}>
            <div class="name-approve">{item.leaderFullName} - </div>{" "}
            <div class="date-approve">
              {DateFormat(item.opinionDate, "dd/mm/yyyy HH:MM")}
            </div>
            <div class="pl-2 w-100">
              {" "}
              {iconRight} {item.content}
            </div>
          </div>
        );
      });
    }
    //end list comment manager

    return (
      <div class="layout-container pd15 document-new-detail">
        <form onSubmit={this.handleSubmit}>
          {/*row1*/}
          <div class="row no-gutters vertical-center h-50-px clTitle bg-white mg-botton">
            {screenName} - {this.state.name}{" "}
            <div class="text-danger font-italic font-s10-c">
              - {this.state.timeLeft}
            </div>
          </div>
          <div class="row no-gutters vertical-center h-50-px bg-white v-center mg-botton2">
                    {this.state.projectName}
          </div>

                {/*row2*/}
                <div class="row">
                    <div className="col-12 col-md-6 col-lg-6 col-sm-6 col-xl-6 pr-1">
                        <div class="row no-gutters vertical-center h-50-px clTitle bg-white mg-botton">
                            Thông tin chủ đầu tư
                        </div>
                        <div class="row no-gutters bg-white mg-botton">
                            <div class="col-3 col-md-2 col-lg-2 col-sm-2 mg-botton1 no-gutters vertical-center pl-1 ">Tên công ty</div>
                            <div class="col-9 mg-botton1 vertical-center "><strong>{this.state.company.name}</strong></div>
                        </div>
                        <div class="row no-gutters bg-white mg-botton">
                            <div class="col-3 col-md-2 col-lg-2 col-sm-2 mg-botton1 vertical-center pl-1">Mã số thuế</div>
                            <div class="col-9 col-md-4 col-lg-4 col-sm-4 mg-botton1 "><strong>{this.state.company.taxCode}</strong></div>
                            <div class="col-3 col-md-1 col-lg-1 col-sm-1 mg-botton1 vertical-center ">Email</div>
                            <div class="col-9 col-md-5 col-lg-5 col-sm-5 mg-botton1 "><strong>{this.state.company.email}</strong></div>
                        </div>
                        <div class="row no-gutters bg-white mg-botton">
                            <div class="col-3 col-md-2 col-lg-2 col-sm-2 mg-botton1 vertical-center pl-1">Số điện thoại</div>
                            <div class="col-9 col-md-4 col-lg-4 col-sm-4 mg-botton1 "><strong>{this.state.company.phoneNumber}</strong></div>
                            <div class="col-3 col-md-1 col-lg-1 col-sm-1 mg-botton1 vertical-center ">Fax</div>
                            <div class="col-9 col-md-5 col-lg-5 col-sm-5 mg-botton1 "><strong>{this.state.company.faxNumber}</strong></div>
                        </div>
                        <div class="row no-gutters bg-white mg-botton2">
                            <div class="col-3 col-md-2 col-lg-2 col-sm-2 mg-botton1 no-gutters vertical-center pl-1">Địa chỉ</div>
                            <div class="col-9 mg-botton1 vertical-center "><strong>{this.state.company.address}</strong></div>
                        </div>

                        <div class="row no-gutters vertical-center h-50-px clTitle bg-white mg-botton">
                            Thông tin người được ủy quyền
                        </div>
                        <div class="row no-gutters bg-white mg-botton">
                            <div class="col-3 col-md-2 col-lg-2 col-sm-2 mg-botton1 vertical-center pl-1">Họ tên: </div>
                            <div class="col-9 col-md-4 col-lg-4 col-sm-4 mg-botton1 "><strong>{this.state.sentUser.fullName}</strong></div>
                            <div class="col-3 col-md-2 col-lg-2 col-sm-2 mg-botton1 vertical-center ">Quốc tịch</div>
                            <div class="col-9 col-md-4 col-lg-4 col-sm-4 mg-botton1 "><strong>Việt Nam</strong></div>
                        </div>
                        <div class="row no-gutters bg-white mg-botton">
                            <div class="col-3 col-md-2 col-lg-2 col-sm-2 mg-botton1 vertical-center pl-1">Số CMND/CCCD:</div>
                            <div class="col-9 col-md-2 col-lg-2 col-sm-2 mg-botton1 "><strong>{this.state.sentUser.identityId}</strong></div>
                            <div class="col-3 col-md-2 col-lg-2 col-sm-2 mg-botton1 vertical-center ">Ngày cấp:</div>
                            <div class="col-9 col-md-2 col-lg-2 col-sm-2 mg-botton1 "><strong>{DateFormat(this.state.sentUser.identityDate,"dd/mm/yyyy")}</strong></div>
                            <div class="col-3 col-md-2 col-lg-2 col-sm-2 mg-botton1 vertical-center ">Nơi cấp:</div>
                            <div class="col-9 col-md-2 col-lg-2 col-sm-2 mg-botton1 "><strong>{this.state.sentUser.identityBy}</strong></div>
                        </div>
                        <div class="row no-gutters bg-white mg-botton">
                            <div class="col-3 col-md-2 col-lg-2 col-sm-2 mg-botton1 vertical-center pl-1">Số điện thoại</div>
                            <div class="col-9 col-md-4 col-lg-4 col-sm-4 mg-botton1 "><strong>{this.state.sentUser.phoneNumber}</strong></div>
                            <div class="col-3 col-md-2 col-lg-2 col-sm-2 mg-botton1 vertical-center ">Email:</div>
                            <div class="col-9 col-9 col-md-4 col-lg-4 col-sm-4 mg-botton1 "><strong>{this.state.sentUser.email}</strong></div>
                        </div>
                        <div class="row no-gutters bg-white mg-botton2">
                            <div class="col-2 mg-botton1 no-gutters vertical-center pl-1">Địa chỉ</div>
                            <div class="col-9 mg-botton1 vertical-center "><strong>{this.state.sentUser.address}</strong></div>
                        </div>

                        <div class="row no-gutters vertical-center h-50-px clTitle bg-white mg-botton">
                            Thông tin dự án
                        </div>
                        <div class="row no-gutters bg-white mg-botton">
                            <div class="col-2 mg-botton1 no-gutters vertical-center pl-1 ">Tên dự án</div>
                            <div class="col-9 mg-botton1 vertical-center "><strong>{this.state.projectName}</strong></div>
                        </div>
                        <div class="row no-gutters bg-white mg-botton">
                            <div class="col-2 mg-botton1 vertical-center pl-1">Danh mục dự án</div>
                            <div class="col-9 mg-botton1 "><strong>{this.state.evaluateRecordsTypeName}</strong></div>
                        </div>
                        <div class="row no-gutters bg-white mg-botton">
                            <div class="col-2 mg-botton1 vertical-center pl-1">Địa điểm</div>
                            <div class="col-9 mg-botton1 "><strong>{this.state.address}, {this.state.lDistrict}, {this.state.lProvince}</strong></div>
                        </div>
                        <div class="row no-gutters bg-white mg-botton2">
                            <div class="col-3 col-md-2 col-lg-2 col-sm-2 mg-botton1 no-gutters vertical-center pl-1">Tổng đầu tư</div>
                            <div class="col-9 col-md-2 col-lg-2 col-sm-2 mg-botton1 vertical-center "><strong>{parseFloat(this.state.totalInvestment)
                                //.toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong></div>
                            {!allowEditGruop && (<div class="col-3 col-md-1 col-lg-1 col-sm-1 col-xl-1 mg-botton1 no-gutters vertical-center">Nhóm</div>)}
                            {!allowEditGruop && (<div class="col-9 col-md-7 col-lg-7 col-sm-7 col-xl-7 mg-botton1 "><strong>{this.state.evaluateRecordsGroupName}</strong></div>)}
                        </div>
                        {allowEditGruop && (
                            <div class="row no-gutters bg-white mg-botton2">
                                <div class="col-3 col-md-2 col-lg-2 col-sm-2 col-xl-2 mg-botton1 no-gutters vertical-center pl-1">Nhóm</div>
                                <div class="col-5 col-md-4 col-lg-4 col-sm-4 col-xl-4 mg-botton1 no-gutters vertical-center pl-1">
                                    <Autocomplete
                                        options={dataGruopLookup}
                                        onChange={(event, value) => {
                                            this.handleChangeAutocomplete(event, value);
                                        }}
                                        value={this.state.gruopAutoCompleteSelected}
                                        getOptionLabel={(option) => option.name}
                                        style={{}}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                required
                                                variant="outlined"
                                                size="small"
                                            />
                                        )}
                                    />
                                </div>
                                <div class="col-4 col-md-2 col-lg-2 col-sm-2 col-xl-2">
                                    <button
                                        class="form-control btn btn-primary"
                                        id="validationDefault01"
                                        type="button"
                                        name="updateComemntManagerModal"
                                        onClick={() => {
                                            this.saveGroup();
                                        }}
                                    >
                                        Lưu
                                     </button>
                                </div>
                            </div>
                        )
                        }

                        <div class="row no-gutters vertical-center h-50-px clTitle bg-white mg-botton">
                            <div className="col-12">Hồ sơ thẩm duyệt</div>
                        </div>
                        <div class="row no-gutters vertical-center bg-white mg-botton2 pl-3 pt-3 pr-2">
                            <table class="table table-bordered">
                                <tbody>{rowlistDocFile}</tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 col-sm-6 col-xl-6 pr-1">
                        <div className="row mr-0">
                            <div className="col-12 pl-2">
                                <div class="row no-gutters vertical-center bg-white mg-botton">
                                    <div class="col-3 col-md-3 col-lg-3 col-sm-3 col-xl-3 mg-botton1 vertical-center pl-1">Ngày nộp HS:</div>
                                    <div class="col-9 col-md-3 col-lg-3 col-sm-3 col-xl-3 mg-botton1 "><strong>{DateFormat( this.state.sendDate, "dd/mm/yyyy hh:mm")}</strong></div>
                                    <div class="col-3 col-md-3 col-lg-3 col-sm-3 col-xl-3 mg-botton1 vertical-center ">Ngày hẹn trả HS:</div>
                                    <div class="col-9 col-9 col-md-3 col-lg-3 col-sm-3 col-xl-3 mg-botton1 "><strong>{DateFormat(this.state.dateExpired, "dd/mm/yyyy hh:mm")}</strong></div>
                                </div>
                                <div class="row no-gutters vertical-center bg-white mg-botton2">
                                    <div class="col-3 col-md-3 col-lg-3 col-sm-3 col-xl-3 mg-botton1 vertical-center pl-1">Mã HS:</div>
                                    <div class="col-9 col-md-3 col-lg-3 col-sm-3 col-xl-3 mg-botton1 text-warning"><strong>{ this.state.code}</strong></div>
                                    <div class="col-3 col-md-3 col-lg-3 col-sm-3 col-xl-3 mg-botton1 vertical-center ">Số HS:</div>
                                    <div class="col-9 col-md-3 col-lg-3 col-sm-3 col-xl-3 mg-botton1 text-success"><strong>{ this.state.recordsNo}</strong></div>
                                </div>
                            </div>
                        </div>
                        {rowResultFile && rowResultFile.length > 0 &&
                            (<div className="row mr-0">
                                <div className="col-12 pl-2">
                                    <div class="row no-gutters vertical-center h-50-px clTitle bg-white mg-botton">
                                    <div className="col-12">Kết quả thẩm duyệt</div>
                                    </div>
                                    <div class="row no-gutters vertical-center bg-white mg-botton2">
                                        <table class="table table-bordered">
                                            <tbody>{rowResultFile}</tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>)
                        }

                        {rowReportFile && rowReportFile.length > 0 &&

                            (<div className="row mr-0">
                                <div className="col-12 pl-2">
                                    <div class="row no-gutters vertical-center h-50-px clTitle bg-white mg-botton">
                                        <div className="col-12">Hồ sơ báo cáo</div>
                                    </div>
                                    <div class="row no-gutters vertical-center bg-white mg-botton2">
                                        <table class="table table-bordered">
                                            <tbody>{rowReportFile}</tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>)
                        }

                        <div className="row mr-0">
                            <div className="col-12 pl-2">
                                <div class="row no-gutters vertical-center h-50-px clTitle bg-white mg-botton">
                                    <div className="col-12">Cán bộ xử lý</div>
                                </div>
                                <div class="row no-gutters vertical-center bg-white mg-botton2">
                                    <div class="overflow-auto sc-cm border border-white h-cm">
                                        <div class="row no-gutters vertical-center">
                                            {leaderCommentItems}
                                        </div>
                                    </div>
                                    {isShowBtnCommentLeader && (
                                        <button
                                            class="btn btn-secondary mt-2 btn-sm"
                                            type="button"
                                            name="updateComemntLeaderModal"
                                            onClick={() => {
                                                this.handleShowModal("updateComemntLeaderModal");
                                            }}
                                        >
                                            Cập nhật ý kiến
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div class="row no-gutters vertical-center mt-4 mg-botton2">
                            <div class="col d-flex justify-content-center">{btnList}</div>
                        </div>
                    </div>
                </div>
                {/*
          <div class="row no-gutters vertical-center h-50-px clTitle bg-white mg-botton">
            Thông tin chủ đầu tư
          </div>
          <div class="row no-gutters vertical-center bg-white mg-botton2">
            <div class="form-row w-100 pt-3">
              <div class="col-6 mb-3 pl-3">
                <label for="validationDefault01">Tên công ty</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault01"
                  value={this.state.company.name}
                  readOnly
                  required
                />
              </div>
              <div class="col-6 mb-3 pl-3">
                <label for="validationDefault02">Mã số thuế</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault02"
                  value={this.state.company.taxCode}
                  readOnly
                  required
                />
              </div>
            </div>

            <div class="form-row w-100">
              <div class="col-6 mb-3 pl-3">
                <label for="validationDefault01">Số điện thoại</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault01"
                  value={this.state.company.phoneNumber}
                  readOnly
                  required
                />
              </div>
              <div class="col-6 mb-3 pl-3">
                <label for="validationDefault02">Số Fax</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault02"
                  value={this.state.company.faxNumber}
                  readOnly
                  required
                />
              </div>
            </div>

            <div class="form-row w-100">
              <div class="col-6 mb-3 pl-3">
                <label for="validationDefault01">Email</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault01"
                  value={this.state.company.email}
                  readOnly
                  required
                />
              </div>
              <div class="col-6 mb-3 pl-3">
                <label for="validationDefault02">Địa chỉ trụ sở</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault02"
                  value={this.state.company.address}
                  readOnly
                  required
                />
              </div>
            </div>
                </div>
                
          {row3}
          <div class="row no-gutters vertical-center h-50-px clTitle bg-white mg-botton">
            Thông tin người được ủy quyền
          </div>
          <div class="row no-gutters vertical-center bg-white mg-botton2">
            <div class="form-row w-100 pt-3">
              <div class="col-6 mb-3 pl-3">
                <label for="validationDefault01">Họ và Tên</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault01"
                  value={this.state.sentUser.fullName}
                  readOnly
                  required
                />
              </div>
              <div class="col-6 mb-3 pl-3">
                <label for="validationDefault02">Số CMND/CCCD</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault02"
                  value={this.state.sentUser.identityId}
                  readOnly
                  required
                />
              </div>
            </div>

            <div class="form-row w-100">
              <div class="col-6 mb-3 pl-3">
                <label for="validationDefault01">Địa chỉ</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault01"
                  value={this.state.sentUser.address}
                  readOnly
                  required
                />
              </div>
              <div class="col-6 mb-3 pl-3">
                <label for="validationDefault02">Ngày cấp</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault02"
                  value={this.state.sentUser.createdBy}
                  readOnly
                  required
                />
              </div>
            </div>

            <div class="form-row w-100">
              <div class="col-6 mb-3 pl-3">
                <label for="validationDefault01">Số điện thoại</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault01"
                  value={this.state.sentUser.phoneNumber}
                  readOnly
                  required
                />
              </div>
              <div class="col-6 mb-3 pl-3">
                <label for="validationDefault02">Nơi cấp</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault02"
                  value={this.state.sentUser.identityBy}
                  readOnly
                  required
                />
              </div>
            </div>

            <div class="form-row w-100">
              <div class="col-6 mb-3 pl-3">
                <label for="validationDefault01">Email</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault01"
                  readOnly
                  value={this.state.sentUser.email}
                  required
                />
              </div>
              <div class="col-6 mb-3 pl-3">
                <label for="validationDefault02">Quốc tịch</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault02"
                  readOnly
                  value="Việt Nam"
                  required
                />
              </div>
            </div>
          </div>
                
          {row4}
          <div class="row no-gutters vertical-center h-50-px clTitle bg-white mg-botton">
            Thông tin dự án
          </div>
          <div class="row no-gutters vertical-center bg-white mg-botton2">
            <div class="form-row w-100 pt-3">
              <div class="col-6 mb-3 pl-3">
                <label for="validationDefault01">Tên dự án</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault01"
                  value={this.state.projectName}
                  readOnly
                  required
                />
              </div>
              <div class="col-6 mb-3 pl-3">
                <label for="validationDefault02">Danh mục dự án</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault02"
                  readOnly
                  value={this.state.evaluateRecordsTypeName}
                  required
                />
              </div>
            </div>

            <div class="form-row w-100">
              <div class="col-6 mb-3 pl-3">
                <div class="form-row w-100 m-0">
                  <div class="col-4">
                    <label for="validationDefault01">Địa điểm</label>
                    <input
                      type="text"
                      class="form-control"
                      id="validationDefault01"
                      readOnly
                      value={this.state.address}
                      required
                    />
                  </div>
                  <div class="col-4">
                    <label for="validationDefault01">Quận/huyện</label>
                    <input
                      type="text"
                      class="form-control"
                      id="validationDefault01"
                      readOnly
                      value={this.state.lDistrict}
                      required
                    />
                  </div>
                  <div class="col-4">
                    <label for="validationDefault01">Tỉnh/thành</label>
                    <input
                      type="text"
                      class="form-control"
                      id="validationDefault01"
                      readOnly
                      value={this.state.lProvince}
                      required
                    />
                  </div>
                </div>
              </div>
              <div class="col-6 mb-3 pl-3">
                <div class="form-row w-100 m-0">
                  <div class="col-5">
                    <label for="validationDefault01">Tổng đầu tư</label>
                    <input
                      type="text"
                      class="form-control"
                      id="validationDefault01"
                      readOnly
                      value={parseFloat(this.state.totalInvestment)
                        //.toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      required
                    />
                    
                    <label for="validationDefault02">Diện tích</label>
                    <input
                      type="text"
                      class="form-control"
                      id="validationDefault02"
                      readOnly
                      value={this.state.acreage}
                      required
                    />
                    
                  </div>

                  {!allowEditGruop ? (
                    <div class="col-7">
                      <label for="validationDefault01">Nhóm</label>
                      <input
                        type="text"
                        class="form-control"
                        id="validationDefault01"
                        readOnly
                        value={this.state.evaluateRecordsGroupName}
                        required
                      />
                    </div>
                  ) : (
                    <div class="col-5">
                      <label for="validationDefault01">Nhóm</label>
                      <Autocomplete
                        options={dataGruopLookup}
                        onChange={(event, value) => {
                          this.handleChangeAutocomplete(event, value);
                        }}
                        value={this.state.gruopAutoCompleteSelected}
                        getOptionLabel={(option) => option.name}
                        style={{}}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />
                    </div>
                  )}
                  {!allowEditGruop ? (
                    ""
                  ) : (
                    <div class="col-2">
                      <label for="validationDefault01"> &nbsp;</label>
                      <button
                        class="form-control btn btn-primary h37"
                        id="validationDefault01"
                        type="button"
                        name="updateComemntManagerModal"
                        onClick={() => {
                          this.saveGroup();
                        }}
                      >
                        Lưu
                      </button>
                    </div>
                  )}

                </div>
              </div>
            </div>
          </div>
          */
                }
          {/*row5
          <div class="row no-gutters vertical-center h-50-px clTitle bg-white mg-botton">
            <div className="col-6">Hồ sơ thẩm duyệt</div>
            <div className="col-6 pr-2"></div>
          </div>
          <div class="row no-gutters vertical-center bg-white mg-botton2 pl-3 pt-3 pr-2">
            <table class="table table-bordered">
              <thead>
                <tr class="d-flex">
                  <th class="col-1">STT</th>
                  <th class="col-5">Tên hồ sơ</th>
                  <th class="col-5">Tập tin</th>
                                {
                                    //<th class="col-1">Xem</th>
                                }
                  <th class="col-1">Tải về</th>
                </tr>
              </thead>
              <tbody>{rowlistDocFile}</tbody>
            </table>
          </div>
          */}
                {/*row5
                {rowResultFile && rowResultFile.length > 0 &&
                    (<div>
                    <div class="row no-gutters vertical-center h-50-px clTitle bg-white mg-botton">
                        <div className="col-6">Kết quả thẩm duyệt</div>
                        <div className="col-6 pr-2"></div>
                    </div>
                    <div class="row no-gutters vertical-center bg-white mg-botton2 pl-3 pt-3 pr-2">
                        <table class="table table-bordered">
                            <thead>
                                <tr class="d-flex">
                                    <th class="col-1">STT</th>
                                    <th class="col-10">Tập tin</th>
                                    <th class="col-1">Tải về</th>
                                </tr>
                            </thead>
                            <tbody>{rowResultFile}</tbody>
                        </table>
                    </div>
                    </div>)
                }
                */}
                {/*row5
                {rowReportFile && rowReportFile.length > 0 &&
                
                    (<div>
                    <div class="row no-gutters vertical-center h-50-px clTitle bg-white mg-botton">
                        <div className="col-6">Hồ sơ báo cáo</div>
                        <div className="col-6 pr-2"></div>
                    </div>
                    <div class="row no-gutters vertical-center bg-white mg-botton2 pl-3 pt-3 pr-2">
                        <table class="table table-bordered">
                            <tbody>{rowReportFile}</tbody>
                        </table>
                    </div>
                    </div>)
                }*/}
          {/*row6*/}
          {/*}
          <div class="row no-gutters vertical-center h-50-px clTitle bg-white mg-botton">
            Lệ phí thẩm duyệt (VND)
          </div>
          <div class="row no-gutters vertical-center bg-white mg-botton2">
            <div class="form-row w-100 pt-3">
              <div class="col-4 mb-3 pl-3">
                <label for="validationDefault01">Tổng mức đầu tư</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault01"
                  readOnly
                  value={parseFloat(this.state.totalInvestment)
                    //.toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  required
                />
              </div>
              <div class="col-4 mb-3 pl-3">
                <label for="validationDefault02">Mức thu</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault02"
                  readOnly
                  value={parseFloat(this.state.fees)
                    //.toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  required
                />
              </div>
              <div class="col-4 mb-3 pl-3">
                <label for="validationDefault02">Lệ phí thu</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault02"
                  readOnly
                  value={parseFloat(this.state.feesRate)
                    //.toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  required
                />
              </div>
            </div>
          </div>
          {*/}

          {/*row7*/}

          {/*row8
          <div class="row no-gutters vertical-center h-50-px clTitle bg-white mg-botton">
            <div class="col-6">Ý kiến lãnh đạo</div>
            <div class="col-6">Ý kiến cán bộ phụ trách</div>
          </div>
          <div class="row no-gutters vertical-center bg-white mg-botton">
            <div class="form-row w-100 pt-3">
              <div class="col-6 mb-3 pl-3 border-secondary">
                <div class="overflow-auto sc-cm border border-white h-cm">
                  <div class="row no-gutters vertical-center">
                    {managerCommentItems}
                  </div>
                </div>
                {isShowBtnCommentManager && (
                  <button
                    class="btn btn-secondary mt-2 btn-sm"
                    type="button"
                    name="updateComemntManagerModal"
                    onClick={() => {
                      this.handleShowModal("updateComemntManagerModal");
                    }}
                  >
                    Cập nhật ý kiến
                  </button>
                )}
              </div>
              <div class="col-6 mb-3 pl-3">
                <div class="overflow-auto sc-cm border border-white h-cm">
                  <div class="row no-gutters vertical-center">
                    {leaderCommentItems}
                  </div>
                </div>
                {isShowBtnCommentLeader && (
                  <button
                    class="btn btn-secondary mt-2 btn-sm"
                    type="button"
                    name="updateComemntLeaderModal"
                    onClick={() => {
                      this.handleShowModal("updateComemntLeaderModal");
                    }}
                  >
                    Cập nhật ý kiến
                  </button>
                )}
              </div>
            </div>
          </div>
          */}
          {/*buton
          <div class="row no-gutters vertical-center mt-4 mg-botton2">
            <div class="col d-flex justify-content-center">{btnList}</div>
          </div>*/}
        </form>
        {/** ====================================================== */}
        {/*updateComemntManagerModal*/}
        <ManagerCommentModal
          data={{
            show: this.state.managerShow,
            handleCloseModal: this.handleCloseModal,
          }}
        ></ManagerCommentModal>

        {/*updateComemntLeaderModal*/}
        <LeaderCommentModal
          data={{
            show: this.state.leaderShow,
            currentStep: this.state.currentStep,
            handleCloseModal: this.handleCloseModal,
          }}
        ></LeaderCommentModal>

        {/*HelpingAddRecords*/}
        <HelpingAddRecord
          data={{
            show: this.state.helpingAddRecordShow,
            handleCloseModal: this.handleCloseModal,
          }}
        ></HelpingAddRecord>
        <div
          class="modal fade"
          id="updateComemntLeaderModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Cập Nhập Ý Kiến
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body"></div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Đóng
                </button>
                <button type="button" class="btn btn-primary">
                  Lưu Thay Đổi
                </button>
              </div>
            </div>
          </div>
        </div>

        {/*ASSIGNUSERPROCESS*/}
        <AssignUserModal
          data={{
            show: this.state.assignUserProcessShow,
            handleCloseModal: this.handleCloseModal,
          }}
        ></AssignUserModal>

        {/**Record Modal */}
        <RecordModal
          data={{
            show: this.state.recordShow,
            title: this.state.titleRecord,
            isNoCertification: this.state.currentStep
              ? this.state.currentStep.isNoCertification
              : null,
            typeRecord: this.state.typeRecord,
            handleCloseModal: this.handleCloseModal,
          }}
        ></RecordModal>

        {/**ConfirmModalView */}
        <ConfirmModalView
          open={this.state.isOpenConfirmModal}
          handleClose={() => {
            this.setState({ ...this.state, isOpenConfirmModal: false });
          }}
          handleAccept={() => {
            this.handleAcceptModalConfirm();
            this.setState({ ...this.state, isOpenConfirmModal: false });
          }}
        ></ConfirmModalView>
        {/*end*/}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  //evaluateRecords: state.evaluateRecords,
  helpingAddRecord: state.helpingAddRecord,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ShowLoading: ShowLoading,
      HiddenLoading: HiddenLoading,
      getUserByDeparment: userAction.getUserByDeparment,
      getHelpingAddRecord: helpingAddRecordAction.getHelpingAddRecord,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(DocumentNewDetail);
