import React from "react";
import { Button, Form, Modal, Container, Row, Col } from "react-bootstrap";
import "./create-user.scss";
import { userManagement } from "../../../../../redux/store/admin/manager-user/manager-user.action";
import { detailUserAction } from "../../../../../redux/store/admin/manager-user/user-management-detail.action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ShowNotification from "../../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../../utils/configuration";

import Autocomplete from "@material-ui/lab/Autocomplete";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TextField from "@material-ui/core/TextField";

const initialState = {
  fullName: "",
  email: "",
  roleId: 0,
  roleObj: { id: 0, name: "" },
  dateOfBirth: null,
  sex: true,
  address: "",
  rankingObj: { id: 0, name: "" },
  rankingId: 0,
  departmentObj: { id: 0, name: "" },
  departmentId: 0,
  titleObj: { id: 0, name: "" },
  titleId: 0,
  positionObj: { id: 0, name: "" },
  positionId: 0,
  description: "",
  fileAvatar: "",
  fileName: "",
  phoneNumber: "",
  active: true,
  documentUploadId: 0,
};

class CreateUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;

    this.handleChange = this.handleChange.bind(this);
    this.submitRef = React.createRef();
    this.inputFileNameRef = React.createRef();
    this.inputFile = React.createRef();
  }

  componentDidMount() {
    if (this.props.userId && this.props.userId !== 0) {
      var param = "id=" + this.props.userId;
      this.props.detail_user_management(param).then((x) => {
        if (this.props.detailUserManagementState.content) {
          var user = this.props.usermanagementstate.content.items.find(
            (user) => user.id === this.props.userId
          );
          if (user) {
            const rankingItem = this.props.rankingstate.content.find(
              (item) => item.id === user.rankingId
            );
            const titleItem = this.props.titlestate.content.find(
              (item) => item.id === user.titleId
            );
            const roleItem = this.props.rolelookupstate.content.find(
              (item) => item.id === user.roleId
            );
            const positionItem = this.props.positionadmstate.content.find(
              (item) => item.id === user.positionId
            );
            const departmentItem = this.props.departmentadmstate.content.find(
              (item) => item.id === user.departmentId
            );
            this.setState({
              fullName: user.fullName ? user.fullName : "",
              email: user.email ? user.email : "",
              roleId: user.roleId ? user.roleId : 0,
              roleObj: roleItem,
              dateOfBirth: user.dateOfBirth ? new Date(user.dateOfBirth) : null,
              sex: user.sex ? user.sex : false,
              address: user.address ? user.address : "",
              rankingObj: rankingItem,
              rankingId: user.rankingId ? user.rankingId : 0,
              departmentObj: departmentItem,
              departmentId: user.departmentId ? user.departmentId : 0,
              titleObj: titleItem,
              titleId: user.titleId ? user.titleId : 0,
              positionObj: positionItem,
              positionId: user.positionId ? user.positionId : 0,
              description: user.description ? user.description : "",
              // fileAvatar: "",
              documentUploadId: this.props.detailUserManagementState.content
                .files
                ? this.props.detailUserManagementState.content.files.fileId
                : 0,
              fileName: user.avatar ? user.avatar : "",
              phoneNumber: user.phoneNumber ? user.phoneNumber : "",
              active: user.status ? user.status : false,
            });
          }
        }
      });
    }
  }

  handleChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  }

  handleClose = () => {
    this.props.onHide();
  };

  handleSelectFile = (e) => {
    e.preventDefault();
    const { files } = e.target;
    this.setState({
      fileName: files[0].name,
      fileAvatar: files[0],
    });
  };

  validate() {
    if (this.props.userId === 0) {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.state.email === "") {
        var email = document.getElementById("email");
        email.focus();
        ShowNotification("Bạn chưa nhập email", NotificationMessageType.Error);
        return false;
      }
      if (!re.test(this.state.email)) {
        var email = document.getElementById("email");
        email.focus();
        ShowNotification(
          "Email không đúng định dạng",
          NotificationMessageType.Error
        );
        return false;
      }
      if (this.state.roleId === 0) {
        var role = document.getElementsByName("role")[0];
        role.focus();
        ShowNotification(
          "Bạn chưa chọn vai trò",
          NotificationMessageType.Error
        );
        return false;
      }
      if (this.state.fileName === "") {
        var fileName = document.getElementById("fileName");
        fileName.focus();
        ShowNotification("Bạn chưa chọn ảnh", NotificationMessageType.Error);
        return false;
      }
    } else {
      if (this.state.roleId === 0) {
        var role = document.getElementsByName("role")[0];
        role.focus();
        ShowNotification(
          "Bạn chưa chọn vai trò",
          NotificationMessageType.Error
        );
        return false;
      }
    }
    return true;
  }

  handleSubmit = () => {
    if (this.validate()) {
      if (this.props.userId === 0) {
        var bodyFormData = new FormData();

        bodyFormData.append("FullName", this.state.fullName);

        bodyFormData.append("Email", this.state.email);

        bodyFormData.append(
          "RoleId",
          this.state.roleId !== 0 ? this.state.roleId : null
        );

        bodyFormData.append("DateOfBirth", this.state.dateOfBirth);

        bodyFormData.append("Address", this.state.address);

        bodyFormData.append("Description", this.state.description);

        bodyFormData.append("PhoneNumber", this.state.phoneNumber);

        bodyFormData.append(
          "RankingId",
          this.state.rankingId !== 0 ? this.state.rankingId : null
        );

        bodyFormData.append(
          "DepartmentId",
          this.state.departmentId !== 0 ? this.state.departmentId : null
        );

        bodyFormData.append(
          "TitleId",
          this.state.titleId !== 0 ? this.state.titleId : null
        );

        bodyFormData.append(
          "PositionId",
          this.state.positionId !== 0 ? this.state.positionId : null
        );

        bodyFormData.append("Sex", this.state.sex);
        bodyFormData.append("DocumentUploadId", 0);
        bodyFormData.append("Status", this.state.active);
        bodyFormData.append("avatar", this.state.fileAvatar);

        this.props.create_user_management(bodyFormData).then((x) => {
          this.props.onHide();
          // if (!this.props.usermanagementstate.error) {
          //   this.props.onHide();
          // }
        });
      } else {
        var params = {
          id: this.props.userId,
          fullName: this.state.fullName,
          email: this.state.email,
          roleId: this.state.roleId !== 0 ? this.state.roleId : null,
          dateOfBirth: this.state.dateOfBirth,
          sex: this.state.sex,
          address: this.state.address,
          description: this.state.description,
          phoneNumber: this.state.phoneNumber,
          documentUploadId: this.state.documentUploadId,
          rankingId: this.state.rankingId !== 0 ? this.state.rankingId : null,
          departmentId:
            this.state.departmentId !== 0 ? this.state.departmentId : null,
          titleId: this.state.titleId !== 0 ? this.state.titleId : null,
          positionId:
            this.state.positionId !== 0 ? this.state.positionId : null,
        };
        this.props.update_user_management(params).then((x) => {
          if (!this.props.usermanagementstate.error) {
            this.props.onHide();
          }
        });
      }
    }
  };

  handleSave = (e) => {
    e.preventDefault();
    this.submitRef.current.click();
  };

  showSelectFile = () => {
    this.inputFile.current.click();
  };

  render() {
    return (
      <Modal
        {...this.props}
        centered
        dialogClassName="modal-login"
        className="dialog-login"
        scrollable={true}
        size="lg"
        className="create-user"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {this.props.userId === 0 ? "Thêm người dùng" : "Sửa người dùng"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {/* onSubmit={this.handleSubmit} */}
            <Form>
              <Form.Row>
                <Form.Group
                  as={Col}
                  // controlId="validationCustom01"
                  className="col-12 col-md-6 col-lg-6"
                >
                  <Form.Label>Họ và tên</Form.Label>
                  <Form.Control
                    name="fullName"
                    onChange={this.handleChange}
                    type="text"
                    value={this.state.fullName}
                  />
                </Form.Group>
                <Form.Group as={Col} className="col-12 col-md-6 col-lg-6">
                  <Form.Label>
                    Email <span className="requite">*</span>
                  </Form.Label>
                  <Form.Control
                    id="email"
                    name="email"
                    onChange={this.handleChange}
                    value={this.state.email}
                    type="email"
                    required
                    readOnly={this.props.userId !== 0 ? true : false}
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group
                  as={Col}
                  // controlId="validationCustom01"
                  className="col-12 col-md-6 col-lg-6"
                >
                  <Form.Label>Vai trò</Form.Label>
                  <Autocomplete
                    value={this.state.roleObj}
                    onChange={(event, value) => {
                      if (value) {
                        this.setState({ roleId: value.id });
                        this.setState({ roleObj: value });
                      } else {
                        this.setState({ roleId: 0 });
                        this.setState({ roleObj: { id: 0, name: "" } });
                      }
                    }}
                    options={
                      this.props.rolelookupstate.content
                        ? this.props.rolelookupstate.content
                        : [{ id: 0, name: "" }]
                    }
                    getOptionLabel={(option) => option.name}
                    style={{}}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        required
                        name="role"
                      />
                    )}
                  />
                </Form.Group>
                <Form.Group as={Col} className="col-12 col-md-6 col-lg-6">
                  <Form.Label>Ngày sinh</Form.Label>
                  <div>
                    <DatePicker
                      name="dateOfBirth"
                      className="form-control"
                      dateFormat="dd/MM/yyyy"
                      selected={this.state.dateOfBirth}
                      onChange={(date) => this.setState({ dateOfBirth: date })}
                    />
                  </div>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group
                  as={Col}
                  // controlId="validationCustom01"
                  className="col-12 col-md-6 col-lg-6"
                >
                  <Form.Label>Giới tính</Form.Label>
                  <Form.Control
                    name="sex"
                    as="select"
                    value={this.state.sex}
                    onChange={this.handleChange}
                  >
                    <option value={true}>Nam</option>
                    <option value={false}>Nữ</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} className="col-12 col-md-6 col-lg-6">
                  <Form.Label>Số điện thoại</Form.Label>
                  <Form.Control
                    name="phoneNumber"
                    onChange={this.handleChange}
                    value={this.state.phoneNumber}
                    type="number"
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group
                  as={Col}
                  // controlId="validationCustom01"
                  className="col-12 col-md-6 col-lg-6"
                >
                  <Form.Label>Địa chỉ</Form.Label>
                  <Form.Control
                    name="address"
                    onChange={this.handleChange}
                    type="text"
                    value={this.state.address}
                  />
                </Form.Group>
                <Form.Group as={Col} className="col-12 col-md-6 col-lg-6">
                  <Form.Label>Cấp bậc</Form.Label>
                  <Autocomplete
                    value={this.state.rankingObj}
                    onChange={(event, value) => {
                      if (value) {
                        this.setState({ rankingId: value.id });
                        this.setState({ rankingObj: value });
                      } else {
                        this.setState({ rankingId: 0 });
                        this.setState({ rankingObj: { id: 0, name: "" } });
                      }
                    }}
                    options={
                      this.props.rankingstate.content
                        ? this.props.rankingstate.content
                        : [{ id: 0, name: "" }]
                    }
                    getOptionLabel={(option) => option.name}
                    style={{}}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" size="small" />
                    )}
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group
                  as={Col}
                  // controlId="validationCustom01"
                  className="col-12 col-md-6 col-lg-6"
                >
                  <Form.Label>Phòng ban</Form.Label>
                  <Autocomplete
                    value={this.state.departmentObj}
                    onChange={(event, value) => {
                      if (value) {
                        this.setState({ departmentId: value.id });
                        this.setState({ departmentObj: value });
                      } else {
                        this.setState({ departmentId: 0 });
                        this.setState({ departmentObj: { id: 0, name: "" } });
                      }
                    }}
                    options={
                      this.props.departmentadmstate.content
                        ? this.props.departmentadmstate.content
                        : [{ id: 0, name: "" }]
                    }
                    getOptionLabel={(option) => option.name}
                    style={{}}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" size="small" />
                    )}
                  />
                </Form.Group>
                <Form.Group as={Col} className="col-12 col-md-6 col-lg-6">
                  <Form.Label>Tiêu đề</Form.Label>
                  <Autocomplete
                    value={this.state.titleObj}
                    onChange={(event, value) => {
                      if (value) {
                        this.setState({ titleId: value.id });
                        this.setState({ titleObj: value });
                      } else {
                        this.setState({ titleId: 0 });
                        this.setState({ titleObj: { id: 0, name: "" } });
                      }
                    }}
                    options={
                      this.props.titlestate.content
                        ? this.props.titlestate.content
                        : [{ id: 0, name: "" }]
                    }
                    getOptionLabel={(option) => option.name}
                    style={{}}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" size="small" />
                    )}
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group
                  as={Col}
                  // controlId="validationCustom01"
                  className="col-12 col-md-6 col-lg-6"
                >
                  <Form.Label>Vị trí</Form.Label>
                  <Autocomplete
                    value={this.state.positionObj}
                    onChange={(event, value) => {
                      if (value) {
                        this.setState({ positionId: value.id });
                        this.setState({ positionObj: value });
                      } else {
                        this.setState({ positionId: 0 });
                        this.setState({ positionObj: { id: 0, name: "" } });
                      }
                    }}
                    options={
                      this.props.positionadmstate.content
                        ? this.props.positionadmstate.content
                        : [{ id: 0, name: "" }]
                    }
                    getOptionLabel={(option) => option.name}
                    style={{}}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" size="small" />
                    )}
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Mô tả</Form.Label>
                  <Form.Control
                    name="description"
                    onChange={this.handleChange}
                    value={this.state.description}
                    as="textarea"
                    rows={3}
                  />
                </Form.Group>
              </Form.Row>

              {this.props.userId === 0 ? (
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>
                      Ảnh <span className="requite">*</span>
                    </Form.Label>
                    <div className="inline">
                      <Button
                        className="w-20"
                        variant="primary"
                        onClick={this.showSelectFile}
                        disabled={this.props.userId !== 0 ? true : false}
                      >
                        Chọn file
                      </Button>
                      <Form.Control
                        id="fileName"
                        name="fileName"
                        value={this.state.fileName}
                        className="bg-white w-80"
                        type="text"
                        // readOnly={true}
                        required
                      />

                      <Form.Control
                        required
                        ref={this.inputFile}
                        onChange={this.handleSelectFile}
                        type="file"
                        accept="image/x-png,image/gif,image/jpeg"
                        className="display-none"
                      />
                    </div>
                  </Form.Group>
                </Form.Row>
              ) : (
                ""
              )}

              <Form.Row>
                <Form.Check
                  name="active"
                  checked={this.state.active}
                  onChange={this.handleChange}
                  type="checkbox"
                  label="Kích hoạt"
                />
              </Form.Row>
              <Button
                ref={this.submitRef}
                type="submit"
                className="display-none"
              ></Button>
            </Form>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-x mg-r-10"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
            HỦY
          </Button>
          <Button variant="primary" onClick={this.handleSubmit}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-save mg-r-10"
              viewBox="0 0 16 16"
            >
              <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z" />
            </svg>
            LƯU
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  rankingstate: state.rankingLookup,
  titlestate: state.titleLookup,
  rolelookupstate: state.roleLookup,
  positionadmstate: state.positionLookupAdm,
  departmentadmstate: state.departmentLookupAdm,
  usermanagementstate: state.userManagement,
  detailUserManagementState: state.detailUserManagement,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      create_user_management: userManagement.createUserManagement,
      update_user_management: userManagement.updateUserManagement,
      detail_user_management: detailUserAction.detailUserManagement,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);
