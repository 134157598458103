import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import "./assign-user-modal.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DualListBox from "react-dual-listbox";

function AssignUserModal(props) {
  const [selectedDualListBox, setSelectedDualListBox] = useState([]);
  useEffect(() => {}, []);
    const dataDualListBox = [];
    const [content, setContent] = useState("");

  const onChangeDualListBox = (selected) => {
    setSelectedDualListBox(selected);
  };

  const onSave = () => {
    props.data.handleCloseModal("save-assign", { data: selectedDualListBox, content: content });
    resetState();
  };
  const onClose = () => {
    props.data.handleCloseModal("close-assign", { data: selectedDualListBox });
    resetState();
  };

  const resetState = () => {
    setSelectedDualListBox([]);
  };

  if (
    props.userByDepartment.content &&
    props.userByDepartment.content.length > 0
  ) {
    props.userByDepartment.content.map((item, index) => {
      let label = item.name;
      let options = [];
      item.users.map((itemSub, indexSub) => {
        options.push({
          value: itemSub.id,
          label:
            itemSub.positionName +
            " - " +
            itemSub.rankingName +
            " - " +
            itemSub.fullName,
        });
      });
      dataDualListBox.push({
        label: label,
        options: options,
      });
    });
  }

  return (
    <Modal centered size="lg" show={props.data.show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Phân công cán bộ xử lý hồ sơ</Modal.Title>
      </Modal.Header>
          <Modal.Body>
        <DualListBox
          options={dataDualListBox}
          selected={selectedDualListBox}
          onChange={onChangeDualListBox}
          icons={{
            moveLeft: "<",
            moveAllLeft: "<<",
            moveRight: ">",
            moveAllRight: ">>",
          }}
              />
              <div class="form-group">
                  <br/>
                  <label for="exampleInputPassword1">Ý kiến</label>
                  <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      value={content}
                      onChange={(event) => {
                          setContent(event.target.value);
                      }}
                      rows="5"
                  ></textarea>
              </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Đóng
        </Button>
        <Button variant="primary" onClick={onSave}>
          Lưu Thay Đổi
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  userByDepartment: state.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AssignUserModal);
