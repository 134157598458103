/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container } from "react-bootstrap";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./role.scss";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import FormControl from "react-bootstrap/FormControl";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import DateFormat from "dateformat";
import history from "../../../common/history";
import UrlCollect from "../../../common/url-collect";
import { userManagement } from "../../../redux/store/admin/manager-user/manager-user.action";
import { detailUserAction } from "../../../redux/store/admin/manager-user/user-management-detail.action";
import * as configuration from "../../../utils/configuration";
import CreateRole from "../modal/role/create-role/create-role";
import FilterRole from "../modal/role/filter-role/filter-role";
import { rankingAction } from "../../../redux/store/admin/ranking/ranking.action";
import { departmentAction } from "../../../redux/store/admin/department/department.action";
import { titleAction } from "../../../redux/store/admin/title/title.action";
import { positionAction } from "../../../redux/store/admin/position/position.action";
import { roleAction } from "../../../redux/store/admin/role/role.action";
import DeleteDialog from "../modal/dialog-delete/dialog-delete.view";
import ResetPassword from "../modal/manager-user/reset-password/reset-password";
import { resetPasswordAction } from "../../../redux/store/admin/manager-user/reset-password.action";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";

import { roleManagement } from "../../../redux/store/admin/role/role-management.action";
import Permission from "../modal/role/permission/permission";
import { lookupScreenAction } from "../../../redux/store/admin/permission/screen-lookup.action";
import { lookupActionAction } from "../../../redux/store/admin/permission/action-lookup.action";

const apiRoot = configuration.getConfiguration(
  configuration.ApiServerKey.APP_API_ROOT
);

const pagination = paginationFactory({
  sizePerPage: 10,
  paginationSize: 5,
  //withFirstAndLast: false,
  alwaysShowAllBtns: true,
  //firstPageText: 'trang dau tien',
  hideSizePerPage: true,
  //showTotal: true,
});

class Role extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createRoleModalShow: false,
      filterModalShow: false,
      isShowDeleteModal: false,
      id: "",
      permissionModalShow: false,
      roleId: 0,
    };
    // this.isList = true;
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    var item = document.getElementById("list-role");
    item.parentElement.parentElement.classList.add("overflow-auto");
    this.props.getLookupScreen();
    this.props.getLookupAction();
    var param =
      "pageIndex=1&pageSize=10000000&sortExpression=modifiedDate+desc";
    this.props.getListRole(param);
  }

  handleFilter = (code, name) => {
    var param =
      "pageIndex=1&pageSize=10000000&sortExpression=modifiedDate+desc";
    if (code) {
      param += "&code=" + code;
    }
    if (name) {
      param += "&name=" + name;
    }
    this.props.getListRole(param);
  };

  handleResetPassword = (password) => {
    var param = "UserId=" + this.state.id + "&Password=" + password;

    this.props.resetPasswordUserManagement(param).then((x) => {
      if (this.props.resetPasswordUserManagementState.content) {
        ShowNotification(
          "Thay đổi mật khẩu thành công",
          NotificationMessageType.Success
        );
      }
    });
  };

  handleUpdate = (row) => {
    // var param = "id=" + row.id;
    // this.props.detailUserManagement(param);
    this.setState({
      createRoleModalShow: true,
      roleId: row.id,
    });
  };
  // filterCallback = (childData) => {
  //   console.log(childData);
  //   this.setState({ filterModalShow: childData });
  // };
  handleDeleteRole = () => {
    this.props.deleteRole(this.state.id);
    this.setState({ isShowDeleteModal: false });
  };

  refresh = (e) => {
    e.preventDefault();
    var param =
      "pageIndex=1&pageSize=10000000&sortExpression=modifiedDate+desc";
    this.props.getListRole(param);
  };

  handleChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  }

  render() {
    const columns = [
      {
        dataField: "code",
        text: "Code",
      },
      {
        dataField: "name",
        text: "Vai trò",
      },
      {
        dataField: "created_by",
        text: "Người tạo",
      },
      {
        dataField: "created_date",
        text: "Ngày tạo",
        formatter: (cellContent, row) => {
          return DateFormat(row.created_date, "dd/mm/yyyy");
        },
      },
      {
        dataField: "modified_by",
        text: "Người sửa",
      },
      {
        dataField: "modified_date",
        text: "Ngày sửa",
        formatter: (cellContent, row) => {
          return DateFormat(row.modified_date, "dd/mm/yyyy");
        },
      },
      {
        dataField: "id",
        text: "Quản lý",
        formatter: (cellContent, row) => {
          if (row.code !== "ADMIN") {
            return (
              <div className="action">
                <a
                  title="Sửa quyền"
                  className="action-item"
                  onClick={() => this.handleUpdate(row)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-pencil-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                  </svg>
                </a>

                <a
                  title="Phân quyền chức năng"
                  onClick={() => {
                    this.setState({
                      permissionModalShow: true,
                      roleId: row.id,
                    });
                  }}
                  className="action-item"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-cast"
                    viewBox="0 0 16 16"
                  >
                    <path d="m7.646 9.354-3.792 3.792a.5.5 0 0 0 .353.854h7.586a.5.5 0 0 0 .354-.854L8.354 9.354a.5.5 0 0 0-.708 0z" />
                    <path d="M11.414 11H14.5a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.5-.5h-13a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5h3.086l-1 1H1.5A1.5 1.5 0 0 1 0 10.5v-7A1.5 1.5 0 0 1 1.5 2h13A1.5 1.5 0 0 1 16 3.5v7a1.5 1.5 0 0 1-1.5 1.5h-2.086l-1-1z" />
                  </svg>
                </a>

                <a
                  title="Xóa quyền"
                  className="action-item"
                  onClick={() => {
                    this.setState({
                      isShowDeleteModal: true,
                      id: row.id,
                    });
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-trash-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                  </svg>
                </a>
              </div>
            );
          }
        },
      },
    ];
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        // this.isList = false;
        // this.setState({ id: row.id, isList: false });
        // if (this.status == 1000) {
        //   history.push(UrlCollect.FileResult + "/" + row.id);
        // } else {
        //   history.push(UrlCollect.FileDetail + "/" + row.id);
        // }
      },
    };
    const products = [];
    if (
      this.props.roleState.content &&
      this.props.roleState.content.items.length > 0
    ) {
      this.props.roleState.content.items.map((item, index) => {
        products.push(item);
      });
    }
    return (
      <div id="list-role" class="list-role layout-container pd15 weblinkl">
        {/**Row 1 */}
        <div class="row no-gutters vertical-center clTitle bg-white mg-botton">
          <Navbar className="catalog" variant="light">
            <Nav>
              <span className="title">Nhóm Quyền</span>
            </Nav>
            <Nav className="mr-auto">
              <a
                className="action-item"
                onClick={() => this.setState({ filterModalShow: true })}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-filter"
                  viewBox="0 0 16 16"
                >
                  <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                </svg>
              </a>
              <a className="action-item" onClick={this.refresh}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-arrow-clockwise"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                  />
                  <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                </svg>
              </a>
            </Nav>
            <div>
              <Button
                onClick={() =>
                  this.setState({ createRoleModalShow: true, roleId: 0 })
                }
                className="button-add"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-plus-circle mg-r-10"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg>
                THÊM QUYỀN
              </Button>
            </div>
          </Navbar>
        </div>
        <div class="row no-gutters vertical-center bg-white mg-botton2 p-2">
          <div className="col-12">
            <BootstrapTable
              rowEvents={rowEvents}
              keyField="id"
              data={products}
              columns={columns}
              pagination={pagination}
              classes="table-striped"
              rowClasses="mytable-hover-row wrap-text"
              hover
              wrapperClasses="table-responsive"
            />
          </div>
        </div>
        {this.state.createRoleModalShow ? (
          <CreateRole
            roleid={this.state.roleId}
            show={this.state.createRoleModalShow}
            onHide={() => this.setState({ createRoleModalShow: false })}
          />
        ) : (
          ""
        )}

        <FilterRole
          // parentFilterCallback={this.filterCallback}
          show={this.state.filterModalShow}
          onHide={() => this.setState({ filterModalShow: false })}
          onFilter={this.handleFilter}
        />
        <DeleteDialog
          isOpen={this.state.isShowDeleteModal}
          onClose={() => this.setState({ isShowDeleteModal: false })}
          onSuccess={this.handleDeleteRole}
        />
        {this.state.permissionModalShow ? (
          <Permission
            show={this.state.permissionModalShow}
            onHide={() => this.setState({ permissionModalShow: false })}
            roleid={this.state.roleId}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  roleState: state.role,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getListRole: roleManagement.getListRole,
      deleteRole: roleManagement.deleteRole,
      getLookupScreen: lookupScreenAction.getLookupScreen,
      getLookupAction: lookupActionAction.getLookupAction,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Role);
