import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import "./qualify-modal.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function QualifyModal(props) {
  const [
    districtAutoCompleteSelected,
    setDistrictAutoCompleteSelected,
  ] = useState(null);
  const [
    provinceAutoCompleteSelected,
    setProvinceAutoCompleteSelected,
  ] = useState(null);
  const [qualifyCode, setQualifyCode] = useState(null);
  const [districtLookupByProvince, setDistrictLookupByProvince] = useState([]);
  const [qualifyDate, setQualifyDate] = useState(new Date());
  const [expiredDate, setExpiredDate] = useState(new Date());
  const [companyName, setCompanyName] = useState(null);
  const [shortName, setShortName] = useState(null);
  const [taxCode, setTaxCode] = useState(null);
  const [issuedDate, setIssuedDate] = useState(new Date());
  const [issuedBy, setIssuedBy] = useState(null);
  const [headquarters, setHeadquarters] = useState(null);
  const [office, setOffice] = useState(null);
  const [representer, setRepresenter] = useState(null);
  const [roleName, setRoleName] = useState(null);
  const [status, setStatus] = useState(true);
  const [type, setType] = useState(null);

  useEffect(() => {
    if (
      props.data &&
      props.data.dataInput &&
      props.data.dataInput.type &&
      props.data.show
    ) {
      setQualifyCode(props.data.dataInput.qualifyCode);
      setQualifyDate(
        props.data.dataInput.qualifyDate
          ? new Date(props.data.dataInput.qualifyDate)
          : ""
      );
      setExpiredDate(
        props.data.dataInput.expiredDate
          ? new Date(props.data.dataInput.expiredDate)
          : ""
      );
      setCompanyName(props.data.dataInput.companyName);
      setShortName(props.data.dataInput.shortName);
      setTaxCode(props.data.dataInput.taxCode);
      setIssuedDate(
        props.data.dataInput.issuedDate
          ? new Date(props.data.dataInput.issuedDate)
          : ""
      );
      setIssuedBy(props.data.dataInput.issuedBy);
      setHeadquarters(props.data.dataInput.headquarters);
      setOffice(props.data.dataInput.office);
      setRepresenter(props.data.dataInput.representer);
      setRoleName(props.data.dataInput.roleName);
      setStatus(props.data.dataInput.status);
      setType(true);

      let lookupProvince = [];
      if (props.lookupProvince.content) {
        lookupProvince = props.lookupProvince.content;
      }
      const provinceItem = lookupProvince.find(
        (itemProvince) => itemProvince.id === props.data.dataInput.provinceId
      );
      let lookupDistric = [];
      if (props.lookupDistric.content && props.lookupDistric.content.items) {
        lookupDistric = props.lookupDistric.content.items;
      }
      const districtItem = lookupDistric.find(
        (itemDistrict) => itemDistrict.id === props.data.dataInput.districtId
      );
      let districtLookupByProvinceFind = lookupDistric.filter((item) => {
        return item.provinceId === provinceItem.id;
      });
      setProvinceAutoCompleteSelected(provinceItem);
      setDistrictLookupByProvince(districtLookupByProvinceFind);
      setDistrictAutoCompleteSelected(districtItem);
    } else {
      setType(false);
    }
  }, [props]);

  const resetState = () => {
    setDistrictAutoCompleteSelected(null);
    setProvinceAutoCompleteSelected(null);
    setQualifyCode(null);
    setDistrictLookupByProvince([]);
    setQualifyDate(new Date());
    setExpiredDate(new Date());
    setCompanyName(null);
    setShortName(null);
    setTaxCode(null);
    setIssuedDate(new Date());
    setIssuedBy(null);
    setHeadquarters(null);
    setOffice(null);
    setRepresenter(null);
    setRoleName(null);
    setStatus(true);
    setType(null);
  };

  const onSave = (event) => {
    event.preventDefault();
    props.data.handleCloseModal("save", {
      districtId: districtAutoCompleteSelected.id,
      provinceId: provinceAutoCompleteSelected.id,
      qualifyCode: qualifyCode,
      qualifyDate: qualifyDate,
      expiredDate: expiredDate,
      companyName: companyName,
      shortName: shortName,
      taxCode: taxCode,
      issuedDate: issuedDate,
      issuedBy: issuedBy,
      headquarters: headquarters,
      office: office,
      representer: representer,
      roleName: roleName,
      status: status,
      type: type,
    });
    resetState();
  };
  const onClose = () => {
    props.data.handleCloseModal("close", {});
    resetState();
  };

  const handleChangeAutocomplete = (event, name, value) => {
    if (name === "districtId") {
      const districtItem = props.lookupDistric.content.items.find(
        (itemDistrict) => itemDistrict.id === value.id
      );
      const provinceItem = props.lookupProvince.content.find(
        (itemProvince) => itemProvince.id === districtItem.provinceId
      );

      setDistrictAutoCompleteSelected(districtItem);
      setProvinceAutoCompleteSelected(provinceItem);
    } else {
      const provinceItem = props.lookupProvince.content.find(
        (itemProvince) => itemProvince.id === value.id
      );
      let districtLookupByProvinceFind = props.lookupDistric.content.items.filter(
        (item) => {
          return item.provinceId === provinceItem.id;
        }
      );
      setProvinceAutoCompleteSelected(provinceItem);
      setDistrictLookupByProvince(districtLookupByProvinceFind);
    }
  };

  return (
    <Modal centered size="lg" show={props.data.show} onHide={onClose}>
      <form
        onSubmit={(e) => {
          onSave(e);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Công Ty Được Hành Nghề PCCC</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="card">
            <div class="card-header font-weight-bold">
              Thông tin đơn vị được cấp phép
            </div>
            <div class="card-body">
              <div class="form-row">
                <div class="form-group col-6">
                  <label for="exampleInputPassword1">Tên đơn vị</label>
                  <input
                    required
                    name="title"
                    type="text"
                    class="form-control"
                    id="validationDefault01"
                    value={companyName}
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                    }}
                    required
                  />
                </div>
                <div class="form-group col-6">
                  <label for="exampleInputPassword1">Tên viết tắt</label>
                  <input
                    required
                    name="title"
                    type="text"
                    class="form-control"
                    id="validationDefault01"
                    value={shortName}
                    onChange={(e) => {
                      setShortName(e.target.value);
                    }}
                    required
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-6">
                  <label for="exampleInputPassword1">Mã số thuế</label>
                  <input
                    required
                    name="title"
                    type="text"
                    class="form-control"
                    id="validationDefault01"
                    value={taxCode}
                    onChange={(e) => {
                      setTaxCode(e.target.value);
                    }}
                    required
                  />
                </div>
                <div class="form-group col-6">
                  <label for="exampleInputPassword1">Nơi Cấp</label>
                  <input
                    required
                    name="title"
                    type="text"
                    class="form-control"
                    id="validationDefault01"
                    value={issuedBy}
                    onChange={(e) => {
                      setIssuedBy(e.target.value);
                    }}
                    required
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-4">
                  <label class="w-100" for="exampleInputPassword1">
                    Ngày cấp
                  </label>
                  <DatePicker
                    className="datepic form-control"
                    selected={issuedDate}
                    onChange={(date) => setIssuedDate(date)}
                    dateFormat="dd/MM/yyyy"
                  />
                </div>
                <div class="form-group col-4">
                  <label for="exampleInputPassword1">Tỉnh/Thành</label>
                  <Autocomplete
                    options={props.lookupProvince.content}
                    onChange={(event, value) => {
                      handleChangeAutocomplete(event, "provinceId", value);
                    }}
                    value={provinceAutoCompleteSelected}
                    getOptionLabel={(option) => option.name}
                    style={{}}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                </div>
                <div class="form-group col-4">
                  <label for="exampleInputPassword1">Quận/Huyện</label>
                  <Autocomplete
                    options={districtLookupByProvince}
                    onChange={(event, value) => {
                      handleChangeAutocomplete(event, "districtId", value);
                    }}
                    value={districtAutoCompleteSelected}
                    getOptionLabel={(option) => option.name}
                    style={{}}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-6">
                  <label for="exampleInputPassword1">
                    Địa chỉ trụ sở chính
                  </label>
                  <input
                    required
                    name="title"
                    type="text"
                    class="form-control"
                    id="validationDefault01"
                    value={headquarters}
                    onChange={(e) => {
                      setHeadquarters(e.target.value);
                    }}
                    required
                  />
                </div>
                <div class="form-group col-6">
                  <label for="exampleInputPassword1">Địa chỉ văn phòng</label>
                  <input
                    required
                    name="title"
                    type="text"
                    class="form-control"
                    id="validationDefault01"
                    value={office}
                    onChange={(e) => {
                      setOffice(e.target.value);
                    }}
                    required
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-6">
                  <label for="exampleInputPassword1">Người đại diện</label>
                  <input
                    required
                    name="title"
                    type="text"
                    class="form-control"
                    id="validationDefault01"
                    value={representer}
                    onChange={(e) => {
                      setRepresenter(e.target.value);
                    }}
                    required
                  />
                </div>
                <div class="form-group col-6">
                  <label for="exampleInputPassword1">Chức vụ</label>
                  <input
                    required
                    name="title"
                    type="text"
                    class="form-control"
                    id="validationDefault01"
                    value={roleName}
                    onChange={(e) => {
                      setRoleName(e.target.value);
                    }}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="card mt-3">
            <div class="card-header font-weight-bold">Thông tin giấy phép</div>
            <div class="card-body">
              <div class="form-row">
                <div class="form-group col-4">
                  <label for="exampleInputPassword1">Số giấy phép</label>
                  <input
                    required
                    name="title"
                    type="text"
                    class="form-control"
                    id="validationDefault01"
                    value={qualifyCode}
                    onChange={(e) => {
                      setQualifyCode(e.target.value);
                    }}
                    required
                  />
                </div>
                <div class="form-group col-4">
                  <label class="w-100" for="exampleInputPassword1">
                    Ngày cấp
                  </label>
                  <DatePicker
                    className="datepic2 form-control"
                    selected={qualifyDate}
                    onChange={(date) => setQualifyDate(date)}
                    dateFormat="dd/MM/yyyy"
                  />
                </div>
                <div class="form-group col-4">
                  <label class="w-100" for="exampleInputPassword1">
                    Ngày hết hạn
                  </label>
                  <DatePicker
                    className="datepic2 form-control"
                    selected={expiredDate}
                    onChange={(date) => setExpiredDate(date)}
                    dateFormat="dd/MM/yyyy"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="form-group mt-3">
            <label for="validationDefault02">Kích hoạt</label>
            <div class="form-check form-check-inline ml-1">
              <input
                class="form-check-input"
                type="checkbox"
                checked={status}
                onChange={(e) => {
                  setStatus(!status);
                }}
                id="defaultCheck1"
                name="status"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Đóng
          </Button>
          <Button type="submit" variant="primary">
            Lưu
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  lookupProvince: state.lookupProvince,
  lookupDistric: state.lookupDistric,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(QualifyModal);
