/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setStateSidebar } from "../../../redux/store/admin/sidebar/sidebar.action";
import { accountAdminAction } from "../../../redux/store/admin/account-adm/acc-adm.action";
import { Navbar, Nav } from "react-bootstrap";
import history from "../../../common/history";
import UrlCollect from "../../../common/url-collect";
import "./header-admin.scss";

let adminInfo = null;
class HeaderView extends React.Component {
  constructor(props) {
    super(props);
    this.clickBtnToggler = this.clickBtnToggler.bind(this);
    this.logoutClick = this.logoutClick.bind(this);
    this.clickBtnToggler2 = this.clickBtnToggler2.bind(this);
  }
  clickBtnToggler() {
    if (this.props.sidebar.isActive) {
      this.props.SetStateSidebar(false);
      document.body.style.overflow = "unset";
    } else {
      this.props.SetStateSidebar(true);
      document.body.style.overflow = "hidden";
    }
  }
  clickBtnToggler2() {
    if (this.props.sidebar.isActive) {
      this.props.SetStateSidebar(false);
      document.body.style.overflow = "unset";
    } else {
      this.props.SetStateSidebar(true);
      document.body.style.overflow = "hidden";
    }
  }
  logoutClick() {
    this.props.Logout(this.state);
  }
  componentWillMount() {}
  render() {
    adminInfo = JSON.parse(sessionStorage.getItem("adminpcc"));
    return (
      <div>
        <Navbar collapseOnSelect expand="lg" id="header-admin" variant="dark">
          <button
            onClick={() => this.clickBtnToggler()}
            className="navbar-toggler d-sm-block d-md-none"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <Nav className="mr-auto">
            <Navbar.Brand>Thẩm Duyệt Thiết Kế PCCC</Navbar.Brand>
          </Nav>
          <Nav>
            {
              //this.state.admin && (
              (this.props.admin.content || adminInfo) && (
                //adminInfo && (
                <ul class="navbar-nav navbar-expand-sm">
                  <li class="nav-item dropdown">
                    <a
                      class="nav-link"
                      href="#"
                      id="navbarDropdownMenuLink"
                      data-toggle="dropdown"
                    >
                      {this.props.admin.content
                        ? this.props.admin.content.email
                        : adminInfo.email}
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0)">
                          <path
                            d="M6.98923 6.74384C7.91571 6.74384 8.71797 6.41155 9.37347 5.75594C10.029 5.10044 10.3613 4.2984 10.3613 3.37181C10.3613 2.44555 10.029 1.6434 9.37337 0.987682C8.71776 0.332289 7.9156 0 6.98923 0C6.06264 0 5.26059 0.332289 4.60509 0.987789C3.94958 1.64329 3.61719 2.44544 3.61719 3.37181C3.61719 4.2984 3.94958 5.10055 4.60519 5.75605C5.2608 6.41144 6.06296 6.74384 6.98923 6.74384Z"
                            fill="#838383"
                          />
                          <path
                            d="M12.8004 10.7654C12.7815 10.4926 12.7433 10.1951 12.687 9.88083C12.6301 9.56424 12.557 9.26496 12.4694 8.99142C12.3789 8.70869 12.2559 8.42948 12.1038 8.16192C11.9459 7.88421 11.7605 7.64239 11.5524 7.4434C11.3348 7.23523 11.0685 7.06785 10.7604 6.94577C10.4534 6.82433 10.1132 6.7628 9.74933 6.7628C9.60641 6.7628 9.4682 6.82144 9.20128 6.99522C9.037 7.10235 8.84485 7.22626 8.63037 7.36329C8.44698 7.48015 8.19853 7.58963 7.89166 7.68875C7.59227 7.78563 7.28828 7.83476 6.98825 7.83476C6.68822 7.83476 6.38434 7.78563 6.08463 7.68875C5.77808 7.58973 5.52963 7.48025 5.34645 7.3634C5.134 7.22764 4.94174 7.10374 4.77501 6.99512C4.50841 6.82133 4.37009 6.7627 4.22717 6.7627C3.86316 6.7627 3.52307 6.82433 3.2162 6.94588C2.90837 7.06775 2.64188 7.23512 2.42409 7.44351C2.21613 7.64261 2.03059 7.88432 1.87294 8.16192C1.72095 8.42948 1.5979 8.70858 1.50732 8.99152C1.41985 9.26507 1.34668 9.56424 1.28986 9.88083C1.23357 10.1946 1.19533 10.4923 1.17642 10.7658C1.15784 11.0336 1.14844 11.3117 1.14844 11.5925C1.14844 12.3233 1.38075 12.9149 1.83887 13.3512C2.29132 13.7818 2.89 14.0002 3.61803 14.0002H10.3591C11.0871 14.0002 11.6856 13.7819 12.1382 13.3512C12.5964 12.9152 12.8287 12.3235 12.8287 11.5924C12.8286 11.3103 12.8191 11.032 12.8004 10.7654Z"
                            fill="#838383"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0">
                            <rect width="14" height="14" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </a>
                    <ul
                      class="dropdown-menu dropdown-menu-right"
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      <li>
                        <a
                          class="dropdown-item"
                          href="#"
                          onClick={() => {
                            history.push({
                              pathname: UrlCollect.Account,
                            });
                          }}
                        >
                          Tài khoản
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          href="#"
                          onClick={() => {
                            history.push({
                              pathname: UrlCollect.ChangePass,
                            });
                          }}
                        >
                          Đổi mật khẩu
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          onClick={this.logoutClick}
                          href="#"
                        >
                          Thoát
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              )
            }
          </Nav>
        </Navbar>

        <div
          onClick={this.clickBtnToggler2}
          class={this.props.sidebar.isActive ? "overlay active" : "overlay"}
        ></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebar: state.sidebar,
  admin: state.admin,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      Logout: accountAdminAction.logout,
      SetStateSidebar: setStateSidebar,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HeaderView);

/*
//Call with dispatch()
const mapState = (state) => ({
  admin: state.admin,
});
const mapDispatch = (dispatch) => ({
  Logout: () => dispatch(accountAdminAction.logout()),
});
export default connect(mapState, mapDispatch)(HeaderView);
*/
