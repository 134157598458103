import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../../loading/loading.store";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";
import Service from "../../../../api/api-service";
import { ApiUrl } from "../../../../api/api-url";
import { CONSTANT } from "../../../../constants/constant";
const service = new Service();

export const GET_USER_MANAGEMENT_OK = "GET_USER_MANAGEMENT_OK";
export const GET_USER_MANAGEMENT_ERR = "GET_USER_MANAGEMENT_ERR";
export const CREATE_USER_MANAGEMENT_OK = "CREATE_USER_MANAGEMENT_OK";
export const CREATE_USER_MANAGEMENT_ERR = "CREATE_USER_MANAGEMENT_ERR";
export const UPDATE_USER_MANAGEMENT_OK = "UPDATE_USER_MANAGEMENT_OK";
export const UPDATE_USER_MANAGEMENT_ERR = "UPDATE_USER_MANAGEMENT_ERR";
export const ACTIVE_USER_MANAGEMENT_OK = "ACTIVE_USER_MANAGEMENT_OK";
export const ACTIVE_USER_MANAGEMENT_ERR = "ACTIVE_USER_MANAGEMENT_ERR";
export const DEACTIVE_USER_MANAGEMENT_OK = "DEACTIVE_USER_MANAGEMENT_OK";
export const DEACTIVE_USER_MANAGEMENT_ERR = "DEACTIVE_USER_MANAGEMENT_ERR";

export const userManagement = {
  getUserManagement,
  createUserManagement,
  activeUserManagement,
  deactiveUserManagement,
  updateUserManagement,
};

function getUserManagementOk(payload) {
  return { type: GET_USER_MANAGEMENT_OK, payload };
}
function getUserManagementErr(payload) {
  return { type: GET_USER_MANAGEMENT_ERR, payload };
}

function createUserManagementOk(payload) {
  return { type: CREATE_USER_MANAGEMENT_OK, payload };
}
function createUserManagementErr(payload) {
  return { type: CREATE_USER_MANAGEMENT_ERR, payload };
}

function updateUserManagementOk(payload) {
  return { type: UPDATE_USER_MANAGEMENT_OK, payload };
}
function updateUserManagementErr(payload) {
  return { type: UPDATE_USER_MANAGEMENT_ERR, payload };
}

function activeUserManagementOk(payload) {
  return { type: ACTIVE_USER_MANAGEMENT_OK, payload };
}
function activeUserManagementErr(payload) {
  return { type: ACTIVE_USER_MANAGEMENT_ERR, payload };
}

function deactiveUserManagementOk(payload) {
  return { type: DEACTIVE_USER_MANAGEMENT_OK, payload };
}
function deactiveUserManagementErr(payload) {
  return { type: DEACTIVE_USER_MANAGEMENT_ERR, payload };
}

function getUserManagement(param) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .get(ApiUrl.GetUserManagement, param)
      .then((response) => {
        dispatch(getUserManagementOk(response));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(getUserManagementErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}

function createUserManagement(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.CreateUser, data)
      .then((response) => {
        dispatch(RemoveOneSlotToLoadingQueue);
        dispatch(
          getUserManagement(
            "pageIndex=1&pageSize=10000000&sortExpression=modifiedDate+desc"
          )
        );
      })
      .catch((error) => {
        dispatch(createUserManagementErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
        dispatch(
          getUserManagement(
            "pageIndex=1&pageSize=10000000&sortExpression=modifiedDate+desc"
          )
        );
      });
  };
}

function updateUserManagement(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.UpdateUser, data)
      .then((response) => {
        dispatch(RemoveOneSlotToLoadingQueue);
        dispatch(
          getUserManagement(
            "pageIndex=1&pageSize=10000000&sortExpression=modifiedDate+desc"
          )
        );
      })
      .catch((error) => {
        dispatch(updateUserManagementErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}

function activeUserManagement(data) {
  var param = "id=" + data;
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.ActiveUser, param)
      .then((response) => {
        dispatch(RemoveOneSlotToLoadingQueue);
        dispatch(
          getUserManagement(
            "pageIndex=1&pageSize=10000000&sortExpression=modifiedDate+desc"
          )
        );
      })
      .catch((error) => {
        dispatch(activeUserManagementErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}

function deactiveUserManagement(data) {
  var param = "id=" + data;

  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.DeActiveUser, param)
      .then((response) => {
        dispatch(RemoveOneSlotToLoadingQueue);
        dispatch(
          getUserManagement(
            "pageIndex=1&pageSize=10000000&sortExpression=modifiedDate+desc"
          )
        );
      })
      .catch((error) => {
        dispatch(deactiveUserManagementErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}
