export const ApiUrl = {
  //Admin
  GetLookupProvinceAdm: "api/admin/Administrative/GetLookupProvince",
  GetListDistrictAdm: "api/admin/Administrative/GetListDistrict",
  GetLookupCategoryAdm: "api/admin/News_Category/GetLookupCategory",
  GetDetailAdm: "api/cms/News/GetDetail/",
  LoginAdm: "api/AccountAdmin/Login",
  UpdateCommuneAdm: "api/admin/Administrative/UpdateCommune",
  CreateCommuneAdm: "api/admin/Administrative/CreateCommune",
  DeleteCommuneAdm: "api/admin/Administrative/DeleteCommune",
  GetListCommuneAdm: "api/admin/Administrative/GetListCommune",
  UpdateDepartmentAdm: "api/admin/Department/Update",
  CreateDepartmentAdm: "api/admin/Department/Create",
  DeleteDepartmentAdm: "api/admin/Department/Delete/",
  GetListAllDepartmentAdm: "api/admin/Department/GetListAll",
  GetLookupDepartmentAdm: "/api/admin/Department/GetLookup",
  UpdateDistrictAdm: "api/admin/Administrative/UpdateDistrict",
  CreateDistrictAdm: "api/admin/Administrative/CreateDistrict",
  DeleteDistrictAdm: "api/admin/Administrative/DeleteDistrict",
  UpdateNewsAdm: "api/admin/News/Update",
  CreateNewsAdm: "api/admin/News/Create",
  DeleteNewsAdm: "api/admin/News/Delete/",
  UpdatePositionAdm: "api/admin/Position/Update",
  CreatePositionAdm: "api/admin/Position/Create",
  DeletePositionAdm: "api/admin/Position/Delete/",
  GetListAllPositionAdm: "api/admin/Position/GetListAll",
  GetLookupPositionAdm: "api/admin/Position/GetLookup",
  UpdateProjectAdm: "api/admin/Project/Update",
  CreateProjectAdm: "api/admin/Project/Create",
  DeleteProjectAdm: "api/admin/Project/Delete/",
  GetListAllProjectAdm: "api/admin/Project/GetListAll",
  UpdateProvinceAdm: "api/admin/Administrative/UpdateProvince",
  CreateProvinceAdm: "api/admin/Administrative/CreateProvince",
  DeleteProvinceAdm: "api/admin/Administrative/DeleteProvince",
  GetListProvinceAdm: "api/admin/Administrative/GetListProvince",
  UpdateLinkServiceAdm: "api/admin/Administrative/UpdateLinkService",
  CreateLinkServiceAdm: "api/admin/Administrative/CreateLinkService",
  DeleteLinkServiceAdm: "api/admin/Administrative/DeleteLinkService",
  GetListLinkServiceAdm: "api/admin/Administrative/GetListLinkService",
  UploadNewsImageAdm: "api/cms/DocumentUpload/UploadNewsImage",
  GetListAllByStatus: "api/admin/EvaluateRecords/GetListAllByStatus",
  GetEvaluateRecordsByID: "api/admin/EvaluateRecords/GetById/",
  GetUserByDepartment: "api/admin/UserAdmin/GetUserByDepartment",
  AssignUserProcessSave: "api/admin/Workflow/AssignmentUserApproval",
  Approve: "api/admin/Workflow/Approval",
  Reject: "/api/admin/Workflow/Reject",
  AddLeaderOpinion: "api/admin/Workflow/AddLeaderOpinion",
  AddUserOpinion: "api/admin/Workflow/UpdateStepOpinion",
  UpdateResultFile: "api/admin/EvaluateRecords/UpdateResultFile",
  SumaryRecords: "api/admin/RecordsReport/SumaryRecords",
  GetTemplateHelpingAddRecords:
    "api/admin/Notification/GetTemplateHelpingAddRecords",
  UpdateHelpingAddRecords: "api/admin/Notification/UpdateHelpingAddRecords",
  GetUserAccountDetail: "api/AccountAdmin/GetUserAccountDetail",
  ChangePassword: "api/AccountAdmin/ChangePassword",
  ForgotPassword: "api/AccountAdmin/ForgotPassword",
  QualifyGetListAll: "api/admin/Qualify/GetListAll",
  QualifyDelete: "api/admin/Qualify/Delete/",
  QualifyCreate: "api/admin/Qualify/Create",
  QualifyUpdate: "api/admin/Qualify/Update",
  QualifyGetDetail: "/api/admin/Qualify/GetDetail/",
  EvaluateProcessing: "api/admin/RecordsReport/EvaluateProcessing",
  EvaluateTypeSumarize: "api/admin/RecordsReport/EvaluateTypeSumarize",
  GetUserManagement: "api/admin/UserAdmin/GetUserManagement",
  CreateUser: "api/admin/UserAdmin/CreateUser",
  UpdateUser: "api/admin/UserAdmin/UpdateUser",
  ActiveUser: "api/admin/UserAdmin/ActiveUser",
  DeActiveUser: "api/admin/UserAdmin/DeActiveUser",
  ResetPassword: "api/admin/UserAdmin/ResetPassword",
  GetUserDetail: "api/admin/UserAdmin/GetUserDetail",

  GetLookupRanking: "api/admin/Ranking/GetLookup",
  GetLookupTitle: "api/admin/Title/GetLookup",

  GetRoleLookup: "api/admin/Role/GetRoleLookup",
  GetListRole: "api/admin/Role/GetListRole",
  GetDetailRole: "api/admin/Role/GetDetailRole",
  CreateRole: "api/admin/Role/CreateRole",
  UpdateRole: "api/admin/Role/UpdateRole",
  DeleteRole: "api/admin/Role/DeleteRole",

  GetScreenLookup: "api/admin/Permission/GetScreenLookup",
  GetActionLookup: "api/admin/Permission/GetActionLookup",
  GetSecurityMatrixDetail: "api/admin/Permission/GetSecurityMatrixDetail",
  UpdateSecurityMatrix: "api/admin/Permission/UpdateSecurityMatrix",

  GetListAllDelegacy: "api/admin/DocApprDelegacy/GetListAll",
  GetLookupDocumentType: "api/admin/Administrative/GetLookupDocumentType",
  GetStepDetail: "api/admin/Workflow/GetStepDetail/",
  GetLookupDocApprStep: "api/admin/Administrative/GetLookupDocApprStep",
  DocApprDelegacyUpd: "api/admin/DocApprDelegacy/Update",
  DocApprDelegacyCreate: "api/admin/DocApprDelegacy/Create",
  DocApprDelegacyDelete: "api/admin/DocApprDelegacy/Delete/",
  SendNotifyComplete: "/api/admin/Notification/SendNotifyComplete",
  GetLookupEvaluateRecordsGroup:
    "api/cms/Administrative/GetLookupEvaluateRecordsGroup",
  UpdateGroup: "api/admin/EvaluateRecords/UpdateGroup",
};
