/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container } from "react-bootstrap";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./list-user.scss";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import FormControl from "react-bootstrap/FormControl";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import DateFormat from "dateformat";
import history from "../../../common/history";
import UrlCollect from "../../../common/url-collect";
import { userManagement } from "../../../redux/store/admin/manager-user/manager-user.action";
import { detailUserAction } from "../../../redux/store/admin/manager-user/user-management-detail.action";
import * as configuration from "../../../utils/configuration";
import CreateUser from "../modal/manager-user/create-user/create-user";
import FilterUser from "../modal/manager-user/filter-user/filter-user";
import { rankingAction } from "../../../redux/store/admin/ranking/ranking.action";
import { departmentAction } from "../../../redux/store/admin/department/department.action";
import { titleAction } from "../../../redux/store/admin/title/title.action";
import { positionAction } from "../../../redux/store/admin/position/position.action";
import { roleAction } from "../../../redux/store/admin/role/role.action";
import DeleteDialog from "../modal/dialog-delete/dialog-delete.view";
import ResetPassword from "../modal/manager-user/reset-password/reset-password";
import { resetPasswordAction } from "../../../redux/store/admin/manager-user/reset-password.action";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";

const apiRoot = configuration.getConfiguration(
  configuration.ApiServerKey.APP_API_ROOT
);

const pagination = paginationFactory({
  sizePerPage: 20,
  paginationSize: 5,
  //withFirstAndLast: false,
  alwaysShowAllBtns: true,
  //firstPageText: 'trang dau tien',
  hideSizePerPage: true,
  //showTotal: true,
});

class ListUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createUserModalShow: false,
      filterModalShow: false,
      isShowActiveModal: false,
      btnName: "",
      contentActiveDeactive: "",
      header: "",
      id: "",
      isActive: true,
      resetPasswordModalShow: false,
      userId: 0,
    };
    // this.isList = true;
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    var item = document.getElementById("list-user");
    item.parentElement.parentElement.classList.add("overflow-auto");
    var param =
      "pageIndex=1&pageSize=10000000&sortExpression=modifiedDate+desc";
    this.props.getUserManagement(param);
    this.props.getRanking();
    this.props.getDepartment();
    this.props.getTitle();
    this.props.getPosition();
    this.props.getRoleLookup();
  }

  handleFilter = (email) => {
    var param =
      "pageIndex=1&pageSize=10000000&sortExpression=modifiedDate+desc";
    if (email) {
      param += "&email=" + email;
    }
    this.props.getUserManagement(param);
  };

  handleResetPassword = (password) => {
    var param = "UserId=" + this.state.id + "&Password=" + password;

    this.props.resetPasswordUserManagement(param).then((x) => {
      if (this.props.resetPasswordUserManagementState.content) {
        ShowNotification(
          "Thay đổi mật khẩu thành công",
          NotificationMessageType.Success
        );
      }
    });
  };

  handleUpdate = (row) => {
    // var param = "id=" + row.id;
    // this.props.detailUserManagement(param);
    this.setState({
      createUserModalShow: true,
      userId: row.id,
    });
  };
  // filterCallback = (childData) => {
  //   console.log(childData);
  //   this.setState({ filterModalShow: childData });
  // };
  handleActiveDeactive = () => {
    if (this.state.isActive) {
      this.props.deactiveUserManagement(this.state.id);
    } else {
      this.props.activeUserManagement(this.state.id);
    }
    this.setState({ isShowActiveModal: false });
  };

  refresh = (e) => {
    e.preventDefault();
    var param =
      "pageIndex=1&pageSize=10000000&sortExpression=modifiedDate+desc";
    this.props.getUserManagement(param);
  };

  handleChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  }

  render() {
    const columns = [
      {
        dataField: "fullName",
        text: "Họ và tên",
        // headerStyle: (colum, colIndex) => {
        //   return {
        //     width: "150px",
        //   };
        // },
      },
      {
        dataField: "email",
        text: "Email",
      },
      {
        dataField: "dateOfBirth",
        text: "Ngày sinh",

        formatter: (cellContent, row) => {
          return DateFormat(row.dateOfBirth, "dd/mm/yyyy");
        },
      },
      {
        dataField: "sex",
        text: "Giới tính",
        formatter: (cellContent, row) => {
          return row.sex ? "Nam" : "Nữ";
        },
      },
      {
        dataField: "phoneNumber",
        text: "Số điện thoại",
      },
      {
        dataField: "address",
        text: "Địa chỉ",
      },
      {
        dataField: "avatar",
        text: "Ảnh đại diện",
        formatter: (cellContent, row) => {
          return <img src={apiRoot + row.avatar} className="logo"></img>;
        },
      },
      {
        dataField: "modifiedDate",
        text: "Ngày sửa",
        formatter: (cellContent, row) => {
          return DateFormat(row.modifiedDate, "dd/mm/yyyy");
        },
      },
      {
        dataField: "status",
        text: "Trạng thái",
        formatter: (cellContent, row) => {
          return row.status ? "Hoạt động" : "Không hoạt động";
        },
      },
      {
        dataField: "statusName",
        text: "Quản lý",
        formatter: (cellContent, row) => {
          return (
            <div className="action">
              <a
                title="Sửa người dùng"
                className="action-item"
                onClick={() => this.handleUpdate(row)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-pencil-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                </svg>
              </a>
              {row.status ? (
                <a
                  title="Khoá tài khoản"
                  onClick={() => {
                    this.setState({
                      isShowActiveModal: true,
                      contentActiveDeactive:
                        "Bạn có muốn khoá tài khoản này không?",
                      header: "Tài khoản này đang hoạt động",
                      btnName: "KHÓA TÀI KHOẢN",
                      id: row.id,
                      isActive: true,
                    });
                  }}
                  className="action-item"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-lock-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
                  </svg>
                </a>
              ) : (
                <a
                  title="Mở tài khoản"
                  onClick={() => {
                    this.setState({
                      isShowActiveModal: true,
                      contentActiveDeactive:
                        "Bạn có muốn mở khoá tài khoản này không?",
                      header: "Tài khoản này đang bị khóa",
                      btnName: "MỞ KHÓA TÀI KHOẢN",
                      id: row.id,
                      isActive: false,
                    });
                  }}
                  className="action-item"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-unlock-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2z" />
                  </svg>
                </a>
              )}

              <a
                title="Đặt lại mật khẩu"
                className="action-item"
                onClick={() =>
                  this.setState({
                    resetPasswordModalShow: true,
                    id: row.id,
                  })
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-key-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M3.5 11.5a3.5 3.5 0 1 1 3.163-5H14L15.5 8 14 9.5l-1-1-1 1-1-1-1 1-1-1-1 1H6.663a3.5 3.5 0 0 1-3.163 2zM2.5 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                </svg>
              </a>
            </div>
          );
        },
      },
    ];
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        // this.isList = false;
        // this.setState({ id: row.id, isList: false });
        // if (this.status == 1000) {
        //   history.push(UrlCollect.FileResult + "/" + row.id);
        // } else {
        //   history.push(UrlCollect.FileDetail + "/" + row.id);
        // }
      },
    };
    const products = [];
    if (
      this.props.userManagementState.content &&
      this.props.userManagementState.content.items.length > 0
    ) {
      this.props.userManagementState.content.items.map((item, index) => {
        products.push(item);
      });
    }
    return (
      <div id="list-user" class="list-user layout-container pd15 weblinkl">
        {/**Row 1 */}
        <div class="row no-gutters vertical-center clTitle bg-white mg-botton">
          <Navbar className="catalog" variant="light">
            <Nav>
              <span className="title">Quản lý người dùng nội bộ</span>
            </Nav>
            <Nav className="mr-auto">
              <a
                className="action-item"
                onClick={() => this.setState({ filterModalShow: true })}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-filter"
                  viewBox="0 0 16 16"
                >
                  <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                </svg>
              </a>
              <a className="action-item" onClick={this.refresh}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-arrow-clockwise"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                  />
                  <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                </svg>
              </a>
            </Nav>
            <div>
              <Button
                onClick={() =>
                  this.setState({ createUserModalShow: true, userId: 0 })
                }
                className="button-add"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-plus-circle mg-r-10"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg>
                THÊM NGƯỜI DÙNG
              </Button>
            </div>
          </Navbar>
        </div>
        <div class="row no-gutters vertical-center bg-white mg-botton2 p-2">
          <div className="col-12">
            <BootstrapTable
              rowEvents={rowEvents}
              keyField="id"
              data={products}
              columns={columns}
              pagination={pagination}
              classes="table-striped"
              rowClasses="mytable-hover-row wrap-text"
              hover
              wrapperClasses="table-responsive"
            />
          </div>
        </div>
        {this.state.createUserModalShow ? (
          <CreateUser
            userId={this.state.userId}
            show={this.state.createUserModalShow}
            onHide={() => this.setState({ createUserModalShow: false })}
          />
        ) : (
          ""
        )}

        <FilterUser
          // parentFilterCallback={this.filterCallback}
          show={this.state.filterModalShow}
          onHide={() => this.setState({ filterModalShow: false })}
          onFilter={this.handleFilter}
        />
        <DeleteDialog
          isOpen={this.state.isShowActiveModal}
          onClose={() => this.setState({ isShowActiveModal: false })}
          onSuccess={this.handleActiveDeactive}
          header={this.state.header}
          btnName={this.state.btnName}
          content={this.state.contentActiveDeactive}
        />
        <ResetPassword
          show={this.state.resetPasswordModalShow}
          onHide={() => this.setState({ resetPasswordModalShow: false })}
          onResetPassword={this.handleResetPassword}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  userManagementState: state.userManagement,
  resetPasswordUserManagementState: state.resetPasswordUserManagement,
  detailUserManagementState: state.detailUserManagement,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserManagement: userManagement.getUserManagement,
      activeUserManagement: userManagement.activeUserManagement,
      deactiveUserManagement: userManagement.deactiveUserManagement,
      detailUserManagement: detailUserAction.detailUserManagement,
      getRanking: rankingAction.getRankingLookup,
      getDepartment: departmentAction.getDepartmentLookup,
      getTitle: titleAction.getTitleLookup,
      getPosition: positionAction.getPositionLookup,
      getRoleLookup: roleAction.getRoleLookup,
      resetPasswordUserManagement:
        resetPasswordAction.resetPasswordUserManagement,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ListUser);
