/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as appActions from "../../../core/app.store";
import history from "../../../common/history";
import UrlCollect from "../../../common/url-collect";
import "./sidebar-admin.scss";

let adminInfo = null;
class SidebarAdmin extends React.Component {
  constructor(props) {
    super(props);
  }
  componentWillMount() {}

  render() {
    adminInfo = JSON.parse(sessionStorage.getItem("adminpcc"));
    const svgNext = () => {
      return (
        <svg
          width="14"
          height="14"
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="caret-right"
          class="svg-inline--fa fa-caret-right fa-w-6"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 192 512"
        >
          <path
            fill="white"
            d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z"
          ></path>
        </svg>
      );
    };

    return (
      <>
        {(this.props.admin.content || adminInfo) && (
          <nav id="sidebar" class={this.props.sidebar.isActive ? "active" : ""}>
            <ul class="list-unstyled components">
              {/**Quan Li Ho So ======================================================================= */}
              <li class="active">
                <a
                  href="#docSubmenu"
                  data-toggle="collapse"
                  aria-expanded="false"
                  class="dropdown-toggle"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.66634 11.2H11.333V12.8H4.66634V11.2ZM4.66634 8H11.333V9.6H4.66634V8ZM9.66634 0H2.99967C2.08301 0 1.33301 0.72 1.33301 1.6V14.4C1.33301 15.28 2.07467 16 2.99134 16H12.9997C13.9163 16 14.6663 15.28 14.6663 14.4V4.8L9.66634 0ZM12.9997 14.4H2.99967V1.6H8.83301V5.6H12.9997V14.4Z"
                      fill="white"
                    />
                  </svg>{" "}
                  {"  "}
                  Quản lí hồ sơ
                </a>
                <ul class="collapse list-unstyled" id="docSubmenu">
                  <li>
                    <a
                      href="#"
                      onClick={() => {
                        history.push(UrlCollect.DashboardAdmin);
                      }}
                    >
                      {svgNext()}
                      {"  "} Thống Kê
                    </a>
                  </li>

                  <li>
                    <a
                      href="#"
                      onClick={() => {
                        history.push({
                          pathname: UrlCollect.DocumentWait,
                        });
                      }}
                    >
                      {svgNext()}
                      {"  "} Hồ sơ chờ tiếp nhận
                    </a>
                  </li>

                  <li>
                    <a
                      href="#"
                      onClick={() => {
                        history.push({
                          pathname: UrlCollect.DocumentNew,
                        });
                      }}
                    >
                      {svgNext()}
                      {"  "} Hồ sơ chờ phân công
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={() => {
                        history.push({
                          pathname: UrlCollect.DocumentProcess,
                        });
                      }}
                    >
                      {svgNext()}
                      {"  "} Hồ sơ đang xử lý
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={() => {
                        history.push({
                          pathname: UrlCollect.DocumentReg,
                        });
                      }}
                    >
                      {svgNext()}
                      {"  "} Hồ sơ trình kí
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={() => {
                        history.push({
                          pathname: UrlCollect.DocumentEval,
                        });
                      }}
                    >
                      {svgNext()}
                      {"  "} Hồ sơ đã thẩm duyệt
                    </a>
                  </li>
                </ul>
              </li>

              {/**Thong tin tai khoan ======================================================================= */}
              <li>
                <a
                  href="#accountSubmenu"
                  data-toggle="collapse"
                  aria-expanded="false"
                  class="dropdown-toggle configSubmenu"
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0)">
                      <path
                        d="M6.98923 6.74384C7.91571 6.74384 8.71797 6.41155 9.37347 5.75594C10.029 5.10044 10.3613 4.2984 10.3613 3.37181C10.3613 2.44555 10.029 1.6434 9.37337 0.987682C8.71776 0.332289 7.9156 0 6.98923 0C6.06264 0 5.26059 0.332289 4.60509 0.987789C3.94958 1.64329 3.61719 2.44544 3.61719 3.37181C3.61719 4.2984 3.94958 5.10055 4.60519 5.75605C5.2608 6.41144 6.06296 6.74384 6.98923 6.74384Z"
                        fill="black"
                      />
                      <path
                        d="M12.8004 10.7654C12.7815 10.4926 12.7433 10.1951 12.687 9.88083C12.6301 9.56424 12.557 9.26496 12.4694 8.99142C12.3789 8.70869 12.2559 8.42948 12.1038 8.16192C11.9459 7.88421 11.7605 7.64239 11.5524 7.4434C11.3348 7.23523 11.0685 7.06785 10.7604 6.94577C10.4534 6.82433 10.1132 6.7628 9.74933 6.7628C9.60641 6.7628 9.4682 6.82144 9.20128 6.99522C9.037 7.10235 8.84485 7.22626 8.63037 7.36329C8.44698 7.48015 8.19853 7.58963 7.89166 7.68875C7.59227 7.78563 7.28828 7.83476 6.98825 7.83476C6.68822 7.83476 6.38434 7.78563 6.08463 7.68875C5.77808 7.58973 5.52963 7.48025 5.34645 7.3634C5.134 7.22764 4.94174 7.10374 4.77501 6.99512C4.50841 6.82133 4.37009 6.7627 4.22717 6.7627C3.86316 6.7627 3.52307 6.82433 3.2162 6.94588C2.90837 7.06775 2.64188 7.23512 2.42409 7.44351C2.21613 7.64261 2.03059 7.88432 1.87294 8.16192C1.72095 8.42948 1.5979 8.70858 1.50732 8.99152C1.41985 9.26507 1.34668 9.56424 1.28986 9.88083C1.23357 10.1946 1.19533 10.4923 1.17642 10.7658C1.15784 11.0336 1.14844 11.3117 1.14844 11.5925C1.14844 12.3233 1.38075 12.9149 1.83887 13.3512C2.29132 13.7818 2.89 14.0002 3.61803 14.0002H10.3591C11.0871 14.0002 11.6856 13.7819 12.1382 13.3512C12.5964 12.9152 12.8287 12.3235 12.8287 11.5924C12.8286 11.3103 12.8191 11.032 12.8004 10.7654Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect width="14" height="14" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  {"  "} Thông tin tài khoản
                </a>
                <ul class="collapse list-unstyled" id="accountSubmenu">
                  <li>
                    <a
                      href="#"
                      onClick={() => {
                        history.push({
                          pathname: UrlCollect.Account,
                        });
                      }}
                    >
                      {svgNext()}
                      {"  "} Tài khoản
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={() => {
                        history.push({
                          pathname: UrlCollect.ChangePass,
                        });
                      }}
                    >
                      {svgNext()}
                      {"  "} Đổi mật khẩu
                    </a>
                  </li>
                </ul>
              </li>

              {/**Cau Hinh he thong ======================================================================= */}
              <li>
                <a
                  href="#configSubmenu"
                  data-toggle="collapse"
                  aria-expanded="false"
                  class="dropdown-toggle configSubmenu"
                >
                  <svg
                    width="14"
                    height="14"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="cog"
                    class="svg-inline--fa fa-cog fa-w-16"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="black"
                      d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"
                    ></path>
                  </svg>
                  {"  "} Cấu hình hệ thống
                </a>
                <ul class="collapse list-unstyled" id="configSubmenu">
                  <li>
                    <a
                      href="#"
                      onClick={() => {
                        history.push(UrlCollect.Delegacy);
                      }}
                    >
                      {svgNext()}
                      {"  "} Ủy quyền xét duyệt
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={() => {
                        history.push(UrlCollect.Weblink);
                      }}
                    >
                      {svgNext()}
                      {"  "} Liên Kết Web
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={() => {
                        history.push(UrlCollect.Province);
                      }}
                    >
                      {svgNext()}
                      {"  "} Tỉnh Thành Phố
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={() => {
                        history.push(UrlCollect.District);
                      }}
                    >
                      {svgNext()}
                      {"  "} Quận Huyện
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={() => {
                        history.push(UrlCollect.Commune);
                      }}
                    >
                      {svgNext()}
                      {"  "} Phường Xã
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={() => {
                        history.push(UrlCollect.Project);
                      }}
                    >
                      {svgNext()}
                      {"  "} Công Trình Dự Án
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={() => {
                        history.push(UrlCollect.Department);
                      }}
                    >
                      {svgNext()}
                      {"  "} Phòng Ban
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={() => {
                        history.push(UrlCollect.Position);
                      }}
                    >
                      {svgNext()}
                      {"  "} Chức Vụ
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={() => {
                        history.push(UrlCollect.ManagerUser);
                      }}
                    >
                      <svg
                        width="14"
                        height="14"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="caret-right"
                        class="svg-inline--fa fa-caret-right fa-w-6"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 192 512"
                      >
                        <path
                          fill="white"
                          d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z"
                        ></path>
                      </svg>
                      {"  "} Quản lý người dùng nội bộ
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={() => {
                        history.push(UrlCollect.Role);
                      }}
                    >
                      <svg
                        width="14"
                        height="14"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="caret-right"
                        class="svg-inline--fa fa-caret-right fa-w-6"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 192 512"
                      >
                        <path
                          fill="white"
                          d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z"
                        ></path>
                      </svg>
                      {"  "} Nhóm quyền
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={() => {
                        history.push(UrlCollect.Qualify);
                      }}
                    >
                      {svgNext()}
                      {"  "} Công Ty Được Hành Nghề PCCC
                    </a>
                  </li>
                </ul>
                {/** ========================================== */}
              </li>
              {/**Huong Dan ======================================================================= */}
              <li>
                <a
                  href="#"
                  class="configSubmenu"
                  onClick={() => {
                    history.push(UrlCollect.News);
                  }}
                >
                  <svg
                    width="14"
                    height="14"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="far"
                    data-icon="newspaper"
                    class="svg-inline--fa fa-newspaper fa-w-18"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                  >
                    <path
                      fill="currentColor"
                      d="M552 64H112c-20.858 0-38.643 13.377-45.248 32H24c-13.255 0-24 10.745-24 24v272c0 30.928 25.072 56 56 56h496c13.255 0 24-10.745 24-24V88c0-13.255-10.745-24-24-24zM48 392V144h16v248c0 4.411-3.589 8-8 8s-8-3.589-8-8zm480 8H111.422c.374-2.614.578-5.283.578-8V112h416v288zM172 280h136c6.627 0 12-5.373 12-12v-96c0-6.627-5.373-12-12-12H172c-6.627 0-12 5.373-12 12v96c0 6.627 5.373 12 12 12zm28-80h80v40h-80v-40zm-40 140v-24c0-6.627 5.373-12 12-12h136c6.627 0 12 5.373 12 12v24c0 6.627-5.373 12-12 12H172c-6.627 0-12-5.373-12-12zm192 0v-24c0-6.627 5.373-12 12-12h104c6.627 0 12 5.373 12 12v24c0 6.627-5.373 12-12 12H364c-6.627 0-12-5.373-12-12zm0-144v-24c0-6.627 5.373-12 12-12h104c6.627 0 12 5.373 12 12v24c0 6.627-5.373 12-12 12H364c-6.627 0-12-5.373-12-12zm0 72v-24c0-6.627 5.373-12 12-12h104c6.627 0 12 5.373 12 12v24c0 6.627-5.373 12-12 12H364c-6.627 0-12-5.373-12-12z"
                    ></path>
                  </svg>
                  {"  "} Hướng dẫn
                </a>
              </li>
            </ul>
          </nav>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
  sidebar: state.sidebar,
  admin: state.admin,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SidebarAdmin);
